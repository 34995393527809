import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { fetchGroup, getInviteLink } from '../store/actions';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { CenteredCircularProgress } from  '../utils/utils';

const useStyles = makeStyles({
  joinBtn: {
    textAlign: "center",
    marginTop: 24,
  },
});

function InnerJoin(props) {

  const classes = useStyles();
  const { group } = props;

  useEffect( () => {
    if(!props.group) {
      props.loadGroup();
    }
    if(!props.inviteLink) {
      props.fetchLink();
    }
  }, []);

  if(!group) return <CenteredCircularProgress />;

  return (
    <div className="feed">
      <Typography variant="h4" gutterBottom>{group.name}</Typography>
      <Typography variant="body1" color="textSecondary" gutterBottom>{group.description}</Typography>
      

      <div className={classes.joinBtn}>
        <Button variant="contained" color="primary" href={props.inviteLink} disabled={!props.inviteLink}>
          Join
        </Button>
      </div>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  const { groupId } = ownProps.match.params;

  let group = null;
  if(state.groups && groupId in state.groups) group = state.groups[groupId];
  let inviteLink = null;
  if(state.inviteLinks && groupId in state.inviteLinks) inviteLink = state.inviteLinks[groupId];


  return {
    loggingIn: state.loggingIn,
    loggedIn: state.loggedIn,
    profile: state.profile,
    group: group,
    inviteLink: inviteLink,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    loadGroup: () => {
      console.log(`Loading group ${ownProps.match.params.groupId}`);
      dispatch(fetchGroup(ownProps.match.params.groupId));
    },
    fetchLink: () => {
      dispatch(getInviteLink(ownProps.match.params.groupId));
    }
  }
}

const GroupJoin = connect(mapStateToProps, mapDispatchToProps)(InnerJoin);
export default GroupJoin;
