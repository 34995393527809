import React from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import {
  fetchGroup, fetchGroups, fetchGroupPayments, openStatusMessage,
} from '../store/actions';

import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import SaveIcon from '@material-ui/icons/Queue';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import TelegramIcon from '@material-ui/icons/Telegram';
import LockIcon from '@material-ui/icons/Lock';
import LinkIcon from '@material-ui/icons/Link';
import CheckIcon from '@material-ui/icons/Check';
import EventIcon from '@material-ui/icons/Event';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import Signin from '../auth/Signin';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import { GCS_DOMAIN, whatsappLink, telegramLink } from '../utils/utils';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import { ScrollToTop, ButtonLink, pluralize, CenteredCircularProgress } from '../utils/utils';

const styles = theme => ({
  dialog: {
    margin: 8,
  },
  group: {
    margin: "0px 0px 16px",
    textAlign: "left",
  },
  priceTag: {
    fontWeight: "bold",
    background: "#fc9898",
    padding: 2,
    marginRight: 8,
    marginBottom: 8,
  },
  members: {
    color: theme.palette.text.secondary,
  },
  groupContent: {
    display: "flex",
  },
  media: {
    margin: "16px 0px 0px 0px",
    width: 100,
    //height: 0,
    // paddingTop: '56.25%', // 16:9
  },
  details: {
    width: "100%",
    padding: "8px 8px 8px 16px",
  },
  description: {
    margin: "8px 0px"
  },
  actions: {
    width: "100%",
    padding: "4px 0px",
    marginTop: 16,
    display: "flex",
    "& button": {
      marginRight: 8,
    }
  },
  shop: {
    marginLeft: "auto",
    marginRight: 16,
  },
  helper: {
    marginTop: 16,
  },
  meetings: {
    marginTop: 16,
  },
  payments: {
    marginTop: 16,
    background: "#fc9898",
    padding: 16,
  },
  meeting: {
    margin: "8px 0px",
  },
  bookBtn: {
    margin: "8px 0px",
  },
});


class InnerGroupPage extends React.PureComponent {
  state = {
    openLogin: false,
    openUpgrade: false,
    expired: false,
    expiry: null,
  };

  componentDidMount() {
    if(!this.props.group) {
      this.props.loadGroup(true);
    } else {
      this.props.loadGroup(false);
    }
    ReactPixel.track("ViewContent", {
        content_ids: ["group_" + this.props.groupId],
        content_type: "product",
        contents: [{id: "group_" + this.props.groupId, quantity: 1}],
    });
  }

  render() {
    const { classes, group } = this.props;
    let card = (<CenteredCircularProgress />);

    if(group && !this.props.isFetchingGroups) {
      let image = "https://inviteonly.gg/logo.png";
      let large_image = "https://inviteonly.gg/logo.png";
      let media = (
        <Avatar>
          { (group.user && group.user.name) ? group.user.name[0].toUpperCase() : group.name[0].toUpperCase() }
        </Avatar>
      );
      if(group.thumb_urls && group.thumb_urls.length>0) {
        image = GCS_DOMAIN + group.thumb_urls.split(',')[0];
        media = (<img src={image} alt={group.name} />);
      }
      if(group.image_urls && group.image_urls.length>0) {
        large_image = GCS_DOMAIN + group.image_urls.split(',')[0];
      }
      let price = null;
      let period = null;
      if(group.membership==="lifetime") period = "one-off";
      else if(group.membership==="yearly") period = "/year";
      else if(group.membership==="monthly") period = "/month";
      const currencyLabel = group.currency ? group.currency.toUpperCase() : null;

      if(group.price_cents && !isNaN(group.price_cents)) {
        price = `${currencyLabel} ${(group.price_cents/100.0).toFixed(2)} ${period}`;
      } else {
        price = 'Free';
      }
      var json_ld = {
          "@context": "http://schema.org",
          "@type": "Product",
          "productID": "group_" + group.group_id,
          "name": group.name,
          "description": group.description,
          "url": `https://inviteonly.gg/group/${group.group_id}`,
          "image": large_image || image,
          "brand": group.user ? (group.user.name || group.user.slug) : "User",
          "offers":[{
            "@type":"Offer",
            "price": (group.price_cents/100.0).toFixed(2),
            "priceCurrency": group.currency || 'USD',
            "itemCondition":"https://schema.org/NewCondition",
            "availability":"https://schema.org/InStock"
            }]
      };


      let payments = null;
      let boughtBefore = false;
      let activeMember = false;
      let showJoin = (group.membership==="free");
      if(this.props.payments && this.props.payments.length>0) {
        boughtBefore = true;
        payments = this.props.payments.map( (p, i) => {
          let payment_date = moment(p.created_at, 'X');
          let expires_date = p.expires_at ? moment(p.expires_at, 'X') : null;
          let payment_paid = (p.currency && p.price_paid)
              ? `${p.currency.toUpperCase()} ${(p.price_paid/100.0).toFixed(2)}`
              : 'free';
          if(p.membership==='lifetime' || !expires_date || expires_date >= moment()) {
            console.log(`Active membership ${p.membership}, expires ${expires_date ? expires_date.format('X') : "NULL"}`);
            activeMember = true;
            showJoin = true;
          } else {
            console.log(`Expired membership ${p.membership}, expires ${expires_date ? expires_date.format('X') : "NULL"}`);
          }
          return (
            <div key={`payment-${i}`}>
              You subscribed for <b>{payment_paid}</b> on {payment_date.format('LL')}.
              { expires_date && <React.Fragment>Expires {expires_date.format('LL')}</React.Fragment>}
            </div>
          );
        });
      }

      let members = null;

      if(group.member_limit==="max" && group.max_members>0) {
        if(group.n_members && group.n_members>1)
          members = `${group.n_members} members (max ${group.max_members})`;
        else
          members = `Max ${group.max_members} members`;
      } else {
        if(group.n_members && group.n_members>1) members = `${group.n_members} members`;
      }

      let mainBtn = (
        <Button className={classes.shop} color="primary" variant="contained" startIcon={<LockIcon />}
            disabled={((this.props.isLoggedIn || this.props.isLoggingIn) && (!this.props.profile))}
            component={ButtonLink}
            to={`/groups/${group.group_id}/pay`}
          >
          {boughtBefore ? "Buy Again" : "Buy Now"}
        </Button>
      );

      if(showJoin) {
        mainBtn = (
          <Button className={classes.shop} color="primary" variant="contained" startIcon={<LinkIcon />}
              disabled={((this.props.isLoggedIn || this.props.isLoggingIn) && (!this.props.profile))}
              component={ButtonLink}
              to={`/groups/${group.group_id}/join`}
            >
            Join
          </Button>
        );
      }

      if(group.member_limit==='max' && group.max_members>0 && group.max_members<=group.n_members) {
        mainBtn = (
          <Button className={classes.shop} color="primary" variant="contained" startIcon={<LockIcon />}
              disabled={true}
            >
            Sold out
          </Button>

        )
      }
      if((!group.group_type || group.group_type==='telegram') && this.props.profile && this.props.profile.metadata && !this.props.profile.metadata.telegram_id) {
        mainBtn = (
          <Button
              className={classes.shop}
              variant="contained" color="secondary"
              href={`https://t.me/InviteOnlyBot?start=${this.props.profile.activation_code}`}
          >
            Activate your account
          </Button>
        );
      }

      card=(
        <div className={classes.group}>
          <ScrollToTop />
          
          <Helmet>
            <title>{`${group.user ? (group.user.name || group.user.slug) : "User"} - ${group.name} | InviteOnly.gg`}</title>
            <meta name="description" content={group.description} />
            <script type="application/ld+json">
              {JSON.stringify(json_ld)}
            </script>
          </Helmet>

          <Typography variant="h4" gutterBottom>{group.name}</Typography>
          <div>
            <span className={classes.priceTag}>{price}</span><br/>
            <span className={classes.members}>
              {members}
            </span>
          </div>
          <div className={classes.groupContent}>
            <div className={classes.media}>
              {media}
            </div>
            <div className={classes.details}>
              <div className={classes.description}>
                {group.user && 
                <Typography variant="body1" color="textPrimary" component="p">
                  by <Link to={`/${group.user.slug}`} className="std-link"><b>{group.user.name}</b></Link>
                </Typography>}
                <Typography variant="body1" color="textSecondary" component="p">
                  {group.description}
                </Typography>
              </div>
            </div>
          </div>
          <div className={classes.description}>
            <div className={classes.actions}>
              <IconButton aria-label="share on WhatsApp"
                  href={whatsappLink(group.name, window.location.href)}
              >
                  <WhatsAppIcon />
              </IconButton>
              <IconButton aria-label="share on Telegram"
                  href={telegramLink(group.name, window.location.href)}
              >
                <TelegramIcon />
              </IconButton>
              {mainBtn}
            </div>
          </div>
          <div className={classes.helper}>
            <Typography variant="body2" color="textSecondary">
              Click on 'Buy' to get the link to the private group.
              { !this.props.isLoggedIn && " You will be required to login using your phone number." }
            </Typography>
          </div>
          {payments &&
            <div className={classes.payments}>
              {payments}
            </div>
          }
        </div>
      );
    };

    return (
      <div className="feed">
        {card}
      </div>
    );
  }

}

const mapStateToProps = (state, ownProps) => {
  const { groupId } = ownProps.match.params;

  let group = null;
  if(state.groups && groupId in state.groups) group = state.groups[groupId];
  let payments = null;
  if(state.groupPayments && groupId in state.groupPayments) {
    payments = state.groupPayments[groupId];
    payments.sort( (x, y) => (x.created_at - y.created_at) );
  }

  return {
    groups: state.groups,
    group: group,
    groupId: groupId,
    payments: payments,
    profile: state.profile,
    user: state.user,
    city_slug: state.city_slug,
    isLoggedIn: state.loggedIn,
    isLoggingIn: state.loggingIn,
    isFetchingProfile: state.isFetchingProfile,
    isFetchingGroups: state.isFetchingGroups,
    isBooking: state.isBooking,
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    loadGroup: (full=true) => {
      console.log(`Loading group ${ownProps.match.params.groupId}`);
      if(full) dispatch(fetchGroup(ownProps.match.params.groupId));
      dispatch(fetchGroupPayments(ownProps.match.params.groupId));
      setTimeout(() => dispatch(fetchGroups(null, null, true)), 500);
    },
  }
}
const GroupPage = connect(mapStateToProps, mapDispatchToProps)(InnerGroupPage);
export default withStyles(styles)(GroupPage);
