import axios from 'axios';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/analytics';

import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import { createBrowserHistory } from 'history';

export const SIGNIN_EVENT = "SIGNIN_EVENT";
export const SAVE_TOKEN = "SAVE_TOKEN";
export const RECEIVE_GEO = "RECEIVE_GEO";
export const REQUEST_PROFILE = "REQUEST_PROFILE";
export const RECEIVE_PROFILE = "RECEIVE_PROFILE";
export const RECEIVE_OTHER_PROFILE = "RECEIVE_OTHER_PROFILE";
export const UPDATE_SESSION = "UPDATE_SESSION";

export const REQUEST_GROUPS = "REQUEST_GROUPS";
export const RECEIVE_GROUPS = "RECEIVE_GROUPS";
export const REQUEST_GROUP_ACTIVATION = "REQUEST_GROUP_ACTIVATION";

export const RECEIVE_MEMBERSHIPS = "RECEIVE_MEMBERSHIPS";

export const REQUEST_GROUP_PAYMENTS = "REQUEST_GROUP_PAYMENTS";
export const RECEIVE_GROUP_PAYMENTS = "RECEIVE_GROUP_PAYMENTS";

export const RECEIVE_INVITE_LINK = "RECEIVE_INVITE_LINK";

export const RECEIVED_STRIPE_CLIENT_SECRET = "RECEIVED_STRIPE_CLIENT_SECRET";
export const RECEIVED_STRIPE_PAYMENT_METHODS = "RECEIVED_STRIPE_PAYMENT_METHODS";
export const REDIRECT_EXPRESS = "REDIRECT_EXPRESS";

export const OPEN_STATUS_MESSAGE = "OPEN_STATUS_MESSAGE";
export const CLOSE_STATUS_MESSAGE = "CLOSE_STATUS_MESSAGE";
export const SELECT_TAB = "SELECT_TAB";

export const SAVING = "SAVING";
export const UPDATE_PAYMENT_STATUS = "UPDATE_PAYMENT_STATUS";

export const signinEvent = (user) => ({ type: SIGNIN_EVENT, user: user });
export const saveToken = (token) => ({ type: SAVE_TOKEN, token: token});

export const requestProfile = () => ({type: REQUEST_PROFILE });
export const receiveProfile = (profile) => ({type: RECEIVE_PROFILE, profile: profile});
export const receiveOtherProfile = (profile) => ({type: RECEIVE_OTHER_PROFILE, profile: profile});
export const receiveGeo = (geo, language) => ({type: RECEIVE_GEO, geo: geo, language: language});

export const requestGroups = () => ({type: REQUEST_GROUPS });
export const receiveGroups = (groups) => ({type: RECEIVE_GROUPS, groups});
export const receiveMemberships = (groups) => ({type: RECEIVE_MEMBERSHIPS, groups});
export const requestGroupActivation = (groupId, activating) => ({type: REQUEST_GROUP_ACTIVATION, groupId, activating});

export const requestGroupPayments = () => ({type: REQUEST_GROUP_PAYMENTS });
export const receiveGroupPayments = (groupPayments) => ({type: RECEIVE_GROUP_PAYMENTS, groupPayments});

export const receiveInviteLink = (groupId, inviteLink) => ({type: RECEIVE_INVITE_LINK, groupId, inviteLink});

export const updateSession = (session) => ({type: UPDATE_SESSION, session});

export const receivedStripeClientSecret = (secret) => ({type: RECEIVED_STRIPE_CLIENT_SECRET, secret: secret});
export const receivedStripePaymentMethods = (paymentMethods) => ({type: RECEIVED_STRIPE_PAYMENT_METHODS, paymentMethods});
export const redirectExpress = url => ({type: REDIRECT_EXPRESS, url});

export const openStatusMessage = (message) => ({type: OPEN_STATUS_MESSAGE, message: message});
export const closeStatusMessage = () => ({type: CLOSE_STATUS_MESSAGE});
export const selectTab = (tab) => ({type: SELECT_TAB, tab: tab});

export const saving = (isSaving) => ({ type: SAVING, isSaving: isSaving });
export const updatePaymentStatus = (status, message=null) => ({type: UPDATE_PAYMENT_STATUS, status, message});

export var backendUrl;
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  // dev code
  backendUrl = 'http://localhost:8080/api/v1'; 
} else {
  // production code
  backendUrl = 'https://api.inviteonly.gg/api/v1'; 
  //backendUrl = 'https://inviteonly.appspot.com/api/v1'; 
}

/* Settings up Google Analytics */
ReactGA.initialize('UA-102687-28', {
  gaOptions: {
    allowLinker: true,
  },
});

const history = createBrowserHistory();
history.listen((location, action) => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname + location.search); // not sure if right to add second
  ReactPixel.pageView();
});

ReactPixel.init('613104772655083');

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyB9j1vSS9BHf3_ot5tson71U6olRlmfzn0",
  authDomain: "inviteonly.firebaseapp.com",
  databaseURL: "https://inviteonly.firebaseio.com",
  projectId: "inviteonly",
  storageBucket: "inviteonly.appspot.com",
  messagingSenderId: "284315045926",
  appId: "1:284315045926:web:1cde12148191751e0196e7",
  measurementId: "G-35RD1X2ZC9"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

export function apiRequest(url, method="get", data=null, token=null) {
  let config = {
    url: `${backendUrl}${url}`,
    method: method,
    headers: {}
  }
  if(data) config.data = data;
  if(token) config.headers['Authorization'] = `Bearer ${token}`;
  return axios(config);
}

export function firebaseLogin() {
  return function(dispatch, getState) {
    // Setup phone authorization callbacks
    firebase.auth().onAuthStateChanged(function(user) {
      if(user) {
        dispatch(signinEvent(user));
        ReactGA.set({ userId: user.uid });
        ReactGA.event({
          category: "user",
          action: "login",
        });
        ReactPixel.track('Lead');
        
        user.getIdToken().then(function(idToken) {
          console.log("Firebase logged-in user");
          // console.log("Firebase userIdToken: " + idToken);
          dispatch(saveToken(idToken));
          dispatch(fetchProfile());
          dispatch(stripePaymentMethods());
          setTimeout(() => { dispatch(fetchGroupPayments()); }, 1000);
          // setTimeout(() => { dispatch(fetchClassBookings()); }, 1200);
          // dispatch(fetchChannels());
          // setTimeout(() => { dispatch(fetchCompanies()); }, 1000);
          // setTimeout(() => { dispatch(fetchContacts()); }, 1000);
          // setTimeout(() => { dispatch(loadPrefs()); }, 1000);
        });
      } else {
        console.log("Not logged-in user");
        // dispatch(fetchProfile());
        dispatch(signinEvent(null));
      }

    });
  }
}

export function logout() {
  return function(dispatch) {
    // dispatch(localSignin(false));
    dispatch(signinEvent(null));
    console.log("Logging out: deleting locally stored profile");
    // del('profile', customStore);
    // TODO: should i also delete channels, etc?
    console.log("Logging out from firebase");
    firebase.auth().signOut();
  }
}

export function firebaseTokenRefresh() {
  return function(dispatch, getState) {
    console.log("Refreshing firebase token");
    var user = firebase.auth().currentUser;
    if(user) {
      user.getIdToken(true).then( idToken => {
          dispatch(saveToken(idToken));
      });

    } else {
      console.log("Can't refresh token, no currentUser on firebase");
    }
  }
}

export function fetchGeo() {
  return function(dispatch, getState) {
    // console.log("Fetching geo and language");
    axios.get(`${backendUrl}/profile`)
      .then( json => {
        // console.log(`fetchGeo received ${JSON.stringify(json.data)}`);
        dispatch(receiveGeo(json.data.geo, json.data.language));
      })
      .catch(function(error) {
        console.log("Could not fetch user geo due to exception");
        console.log(error);
      });
  }
}

export function fetchProfile(slug=null) {
  return function(dispatch, getState) {
    var token = getState().token;
    if(!slug) {
      if(token) {
        dispatch(requestProfile());
        apiRequest("/profile", "get", null, token)
          .then( json => {
            console.log(`fetchProfile received ${JSON.stringify(json.data)}`);
            dispatch(receiveProfile(json.data.user));
          })
          .catch( error => {
            console.log("Could not fetch user profile due to exception");
            console.log(error);
            dispatch(openStatusMessage("Could not connect."));
          });
      }
    } else {
        apiRequest(`/profile/${slug}`, "get", null, token)
          .then( json => {
            dispatch(receiveOtherProfile(json.data.user));
          })
          .catch( error => {
            console.log(`Could not fetch user ${slug} profile due to exception`);
            console.log(error);
            dispatch(openStatusMessage("Could not connect."));
          });
      
    }
  }
}


export function saveProfile(profile, silent=false) {
  return function(dispatch, getState) {
    var token = getState().token;
    if(!profile) profile = getState().profile;

    if(!profile || !profile.name) {
      dispatch(openStatusMessage("Please enter a name to save."));
      return;
    }

    if(token) {
      console.log(`Saving profile ${JSON.stringify(profile)}`);
      if(!silent) dispatch(openStatusMessage("Saving profile..."));
      dispatch(saving(true));
      apiRequest("/profile", "put", profile, token)
        .then( json => {
          // console.log("From saveProfile, received json " + JSON.stringify(json));
          if(!silent) dispatch(openStatusMessage("Profile saved."));
          dispatch(saving(false));
          dispatch(fetchProfile());
        })
        .catch( error => {
          console.log("Could not save profile due to exception");
          console.log(error);
          dispatch(openStatusMessage("Could not save profile"));
          dispatch(saving(false));
        });

    } else {
      console.log("Can't save profile because logged out");
    }

  }
}

export function fetchGroups(userSlug=null, category=null, background=false) {
  return function(dispatch, getState) {
    if(!background) dispatch(requestGroups());
    let url = `/groups`;
    if(userSlug) {
      url += `/${userSlug}`;
      if(category) {
        url += `/${category}`;
      }
    } else {
      if(category) {
        url += `/all/${category}`;
      }
    }
    console.log(`fetchGroups fetching url ${url}`);
    apiRequest(url, "get")
      .then( json => {
        dispatch(receiveGroups(json.data.groups));
      })
      .catch( error => {
        console.log("Could not fetch groups due to exception");
        console.log(error);
        dispatch(openStatusMessage("Could not connect"));
      });
  }
}

export function fetchOwnGroups(background=false) {
  return function(dispatch, getState) {
    const token = getState().token;
    if(!background) dispatch(requestGroups());
    console.log(`fetchOwnGroups fetching own groups`);
    apiRequest('/user/groups', "get", null, token)
      .then( json => {
        dispatch(receiveGroups(json.data.groups));
      })
      .catch( error => {
        console.log("Could not fetch own groups due to exception");
        console.warn(error);
        dispatch(openStatusMessage("Could not connect"));
      });
  }
}

export function fetchMemberships() {
  return function(dispatch, getState) {
    dispatch(requestGroups());
    const token = getState().token;
    let url = `/memberships`;
    console.log(`fetchMemberships fetching url ${url}`);
    apiRequest(url, "get", null, token)
      .then( json => {
        console.log(`Received memberships: ${JSON.stringify(json.data.groups)}`);
        dispatch(receiveMemberships(json.data.groups));
      })
      .catch( error => {
        console.error("Could not fetch memberships due to exception");
        console.log(error);
        dispatch(openStatusMessage("Could not connect"));
      });
  }
}

export function fetchGroup(groupId) {
  return function(dispatch, getState) {
    dispatch(requestGroups());
    apiRequest(`/group/${groupId}`, "get")
      .then( json => {
        if(json.data.group)
          dispatch(receiveGroups([json.data.group]));
        else {
          console.log(`Received an empty group ${JSON.stringify(json.data)}`);
        }
      })
      .catch( error => {
        console.log("Could not fetch groups due to exception");
        console.warn(error);
        dispatch(openStatusMessage("Could not connect"));
      });
  }
}

export function activateGroup(groupId, telegramChatId) {
  return function(dispatch, getState) {
    const token = getState().token;
    dispatch(requestGroupActivation(groupId, true));
    apiRequest(`/group/${groupId}/activate?chat_id=${telegramChatId}`, "post", null, token)
      .then(json => {
        console.log(`Activated group: ${JSON.stringify(json.data)}`);
        dispatch(requestGroupActivation(groupId, false));
        if (json.data.activated) {
          dispatch(openStatusMessage("Group activated successfully"));
          if(json.data.group) dispatch(receiveGroups([json.data.group]));
        } else {
          dispatch(openStatusMessage("Group is already connected to a different chat"));

        }
      })
      .catch( error => {
        dispatch(requestGroupActivation(groupId, false));
        console.log("Could not activate group due to exception");
        console.warn(error);
        dispatch(openStatusMessage("Could not activate group."));
      });

  }
}

export function connectTelegramAccount(telegramId) {
  return function(dispatch, getState) {
    const token = getState().token;
    apiRequest(`/profile/activate?telegram_id=${telegramId}`, "post", null, token)
      .then(json => {
        dispatch(openStatusMessage("Connected to Telegram account"));
        dispatch(fetchProfile());
      })
      .catch( error => {
        console.log("Could not activate user with telegramId due to exception");
        console.warn(error);
        dispatch(openStatusMessage("Could not connect Telegram account."));
      });
  }
}

export function getInviteLink(groupId) {
  return function(dispatch, getState) {
    const token = getState().token;
    if(token) {
      apiRequest(`/group/${groupId}/link`, "post", null, token)
        .then(json => {
          console.log(`getInviteLink for group ${groupId}: ${JSON.stringify(json.data)}`);
          dispatch(receiveInviteLink(groupId, json.data.invite_link))
        })
        .catch( error => {
          console.log("Could not get invite link due to exception");
          console.warn(error);
          if(error.response) {
            if(error.response.status===401)
              dispatch(openStatusMessage("You are not authorized to join this group."));
          } else {
            dispatch(openStatusMessage("Could not get invite link to the group."));
          }
        });
    } else {
      console.warn(`Can't get invite link to group ${groupId} because logged out`);

    }
  }
}

export function saveGroup(group, silent=false) {
  return function(dispatch, getState) {
    const token = getState().token;

    if(!group || !group.name) {
      dispatch(openStatusMessage("Please enter a name to save."));
      return;
    }

    if(token) {
      console.log("Saving group");
      if(!silent) dispatch(openStatusMessage("Saving group..."));
      dispatch(saving(true));
      apiRequest("/groups", "post", group, token)
        .then( json => {
          if(!silent) dispatch(openStatusMessage("Group saved."));
          dispatch(saving(false));
          dispatch(fetchOwnGroups());
          // dispatch(receiveGroups([json.data.group]));
        })
        .catch( error => {
          console.log("Could not save group due to exception");
          console.log(error);
          dispatch(openStatusMessage("Could not save group"));
          dispatch(saving(false));
        });

    } else {
      console.warn("Can't save group because logged out");
    }

  }
}

export function deleteGroup(groupId) {
  return function(dispatch, getState) {
    const token = getState().token;

    if(!groupId) {
      dispatch(openStatusMessage("Select a group to delete"));
      return;
    }

    if(token) {
      console.log(`Deleting group ${groupId}`);
      dispatch(openStatusMessage("Deleting group..."));
      dispatch(saving(true));
      apiRequest(`/group/${groupId}`, "delete", null, token)
        .then( json => {
          dispatch(openStatusMessage("Group deleted."));
          dispatch(saving(false));
          dispatch(fetchOwnGroups(true));
        })
        .catch( error => {
          console.warn("Could not delete group due to exception");
          console.log(error);
          dispatch(openStatusMessage("Could not delete group"));
          dispatch(saving(false));
        });

    } else {
      console.warn("Can't delete group because logged out");
    }

  }
}

export function fetchGroupPayments(groupId=null) {
  return function(dispatch, getState) {
    var token = getState().token;
    if(token) {
      dispatch(requestGroupPayments());
      let url = '/payments';
      if(groupId)
        url = `/group/${groupId}/payments`;

      apiRequest(url, "get", null, token)
        .then( json => {
          dispatch(receiveGroupPayments(json.data.group_payments));
        })
        .catch( error => {
          console.log("Could not fetch group payments due to exception");
          console.log(error);
          dispatch(openStatusMessage("Could not connect"));
        });
    } else {
      console.log("Can't fetch class payments because logged out");
    }
  }
}

export function connectStripeAccount(code) {
  return function(dispatch, getState) {
    const token = getState().token;
    if(token) {
      console.log(`Connecting with Stripe express account ${code}`);
      dispatch(openStatusMessage("Saving payment details..."));
      apiRequest(`/user/connect`, "post", { code }, token)
        .then( json => {
          console.log("From connectStripeAccount, received json " + JSON.stringify(json));
          dispatch(openStatusMessage("Payment details saved."));
          dispatch(fetchProfile());
        })
        .catch( error => {
          console.log("Could not connect with Stripe Express due to exception");
          console.log(error);
          dispatch(openStatusMessage("Could not save payment details."));
        });

    } else {
      console.log("Can't connect with Stripe Express because logged out");
    }

  }
}

export function getStripeExpressLink() {
  return function(dispatch, getState) {
    const token = getState().token;
    if(token) {
      console.log("Getting link for Stripe Express Dashboard");
      apiRequest('/user/express', 'get', null, token)
        .then( json => {
          console.log(`Stripe Express link ${JSON.stringify(json)}`);
          dispatch(redirectExpress(json.data.url));
        })
        .catch( error => {
          console.error("Could not redirect tu Express Dashboard due to exception");
          console.log(error);
        });
    } else {
      console.log("Can't get Express link because logged out");
    }
  }
}

export function stripeIntent(groupId=null) {
  return function(dispatch, getState) {
    const token = getState().token;
    if(token) {
      console.log(`Setting up Stripe intent`);
      dispatch(updatePaymentStatus(null, null));
      let ref = null;
      if(document.cookie) {
        let refCookie = document.cookie.split('; ').find(row => row.startsWith('ref'));
        if(refCookie) ref = refCookie.split('=')[1];
      }
      apiRequest(`/stripe/setup`, "post", { group_id: groupId, ref: ref }, token)
        .then( json => {
          console.log("From stripeIntent, received json " + JSON.stringify(json));
          dispatch(receivedStripeClientSecret(json.data.stripe_client_secret));
        })
        .catch( error => {
          console.log("Could not setup Stripe SetupIntent due to exception");
          console.log(error);
        });

    } else {
      console.log("Can't setup Stripe SetupIntent because logged out");
    }

  }
}

export function stripePaymentMethods() {
  return function(dispatch, getState) {
    const token = getState().token;
    if(token) {
      console.log(`Fetching user payment methods`);
      apiRequest(`/stripe/payment_method`, "get", null, token)
        .then( json => {
          console.log("From stripePaymentMethods, received json " + JSON.stringify(json));
          dispatch(receivedStripePaymentMethods(json.data.stripe_payment_methods));
        })
        .catch( error => {
          console.log("Could not get Stripe payment methods due to exception");
          console.warn(error);
        });

    } else {
      console.log("Can't fetch Stripe payment methods because logged out");
    }
  }
}

export function stripeConfirmCardSetup(stripe, secret, data) {
  return function(dispatch, getState) {
    const token = getState().token;

    dispatch(updatePaymentStatus("submitted"));
    stripe.confirmCardSetup(secret, data)
      .then(function(result) {

        if (result.error) {
          // Display result.error.message in your UI.
          console.warn("Could not save credit card");
          console.log(result.error);
          dispatch(updatePaymentStatus("failed", result.error));
          dispatch(openStatusMessage(`Could not save credit card ${result.error}`));
        } else {
          console.log("Saved credit card successfully");
          console.log(JSON.stringify(result));
          dispatch(updatePaymentStatus("success"));
          dispatch(openStatusMessage("Credit card saved"));

          // The setup has succeeded. Display a success message and send
          // result.setupIntent.payment_method to your server to save the
          // card to a Customer
 
          apiRequest(`/stripe/payment_method`, "post", result.setupIntent, token)
            .then( json => {
              console.log("From stripeConfirmCardSetup, received json " + JSON.stringify(json));
            })
            .catch( error => {
              console.warn("Could not save payment_method in stripeConfirmCardSetup due to exception");
              console.log(error);
            });
        }
      })
      .catch( error => {
        console.warn("stripe.confirmCardSetup failed due to exception");
        dispatch(updatePaymentStatus("failed", error));
        console.log(error);
      });

    dispatch(saveProfile(null, true));
  }
}

export function stripeConfirmCardPayment(stripe, secret, data) {
  return function(dispatch, getState) {
    const token = getState().token;

    dispatch(updatePaymentStatus("submitted"));
    stripe.confirmCardPayment(secret, data)
      .then(function(result) {

        if (result.error) {
          // Display result.error.message in your UI.
          console.warn("Could not pay with credit card");
          console.log(result.error);
          dispatch(openStatusMessage(`Could not pay with credit card: ${result.error.message}`));
          dispatch(updatePaymentStatus("failed", result.error.message));
        } else {
          if(result.paymentIntent.status === 'succeeded') {
            console.log("Paid with credit card successfully");
            console.log(JSON.stringify(result));
            dispatch(updatePaymentStatus("success"));
            dispatch(openStatusMessage("Credit card payment successful"));
            setTimeout(() => { dispatch(fetchGroupPayments()); }, 1000);
          } else {
            console.warn(`Credit card payment failed ${result.paymentIntent.status}`);
            dispatch(openStatusMessage(`Credit card payment failed: ${result.paymentIntent.status}`));
            dispatch(updatePaymentStatus("failed", result.paymentIntent.status));
          }

          apiRequest(`/stripe/payment_method`, "post", result.paymentIntent, token)
            .then( json => {
              console.log("From stripeConfirmCardPayment, received json " + JSON.stringify(json));
            })
            .catch( error => {
              console.warn("Could not save payment_method in stripeConfirmCardPayment due to exception");
              console.log(error);
            });
        }
      })
      .catch( error => {
        dispatch(updatePaymentStatus("failed", error));
        console.warn("stripe.confirmCardPayment failed due to exception");
        console.log(error);
      });
    dispatch(saveProfile(null, true));
  }
}

export function stripeConfirmCardSubscription(stripe, secret, data, group) {
  return function(dispatch, getState) {
    const token = getState().token;

    dispatch(updatePaymentStatus("submitted"));
    stripe.confirmCardSetup(secret, data)
      .then(function(result) {

        if (result.error) {
          // Display result.error.message in your UI.
          console.warn("Could not save credit card");
          console.log(result.error);
          dispatch(updatePaymentStatus("failed", result.error));
          dispatch(openStatusMessage(`Could not save credit card ${result.error}`));
        } else {
          console.log("Saved credit card successfully");
          console.log(JSON.stringify(result));
          // dispatch(updatePaymentStatus("credit_card_saved")); // seems better to keep in "submitted" work in progress
          dispatch(openStatusMessage("Credit card saved"));

          // The setup has succeeded. Display a success message and send
          // result.setupIntent.payment_method to your server to save the
          // card to a Customer
          
          let payload = {
            group_id: group.group_id,
            intent: result.setupIntent,
            payment_method: result.setupIntent.payment_method,
          };

          console.log(`Submitting ${JSON.stringify(payload)} to /stripe/subscription`);
          
          apiRequest(`/stripe/subscription`, "post", payload, token)
            .then( json => {
              console.log("From stripeConfirmCardSubscription, received json " + JSON.stringify(json));
              dispatch(updatePaymentStatus("success"));
              dispatch(openStatusMessage("Subscription successful"));
            })
            .catch( error => {
              dispatch(openStatusMessage("Could not submit subscription"));
              console.warn("Could not submit subscription from stripeConfirmCardSubscription due to exception");
              console.log(error);
              if(error.response) {
                dispatch(updatePaymentStatus("failed", `Error: ${error.response.data ? error.response.data.error : JSON.stringify(error)}`));
              } else {
                dispatch(updatePaymentStatus("failed", `Error: ${JSON.stringify(error)}`));
              }
            });
        }
      })
      .catch( error => {
        console.warn("stripe.confirmCardSetup failed due to exception");
        dispatch(updatePaymentStatus("failed", error));
        console.log(error);
      });

    dispatch(saveProfile(null, true));
  }
}
