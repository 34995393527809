import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { updateSession, fetchMemberships } from '../store/actions';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormGroup from '@material-ui/core/FormGroup';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { ButtonLink, CenteredCircularProgress } from '../utils/utils';
import LoggedHome from './LoggedHome';
import GroupPreview from '../groups/GroupPreview';

const useStyles = makeStyles({
  hero: {
    padding: "16px 0px",
  },
  newBtn: {
    marginTop: 40,
  },
  more: {
    marginTop: 40,
  }
});

function InnerHomePage(props) {
  const classes = useStyles();

  useEffect( () => {
    props.load();
  }, []);   // passing empty array will have it run only once

  const handleSubmit = e => {
    e.preventDefault();

  };

  if(props.loggingIn || !props.profile) return (
    <CenteredCircularProgress />
  );

  let groups = (
    <div style={{alignText: "center"}}>
      <Typography variant="body1" gutterBottom>
        You haven't joined any group yet. Get your friends to invite you to a group or start your own group.
      </Typography>
      <div className={classes.newBtn}>
        <Button
            color="primary" variant="contained"
            component={ButtonLink}
            to="/groups/new"
        >
          Start a new group!
        </Button>
      </div>
    </div>
  );

  if(props.memberships && props.memberships.length>0) {
    let filteredGroups = Object.values(props.memberships);
    filteredGroups = filteredGroups
        .filter( x => x.status!=="deleted" );
    // sort by novelty
    filteredGroups.sort( (d1, d2) => -(d1.created_at - d2.created_at) );
    groups = filteredGroups
      .map( (group, i) => {
        console.log(`Group ${group.group_id} user ${group.user_id}, profile user_id ${props.profile.user_id}`);
        return <GroupPreview group={group} user_id={props.profile.user_id} key={`group-${i}`} />
      }
    );

  }

  return (
    <div className="feed">
      <div className={classes.hero}>
        <h1>Groups you joined</h1>
        {props.isFetching ? <CenteredCircularProgress /> : groups}

        <div className={classes.more}>
          <h1>Earn money</h1>
          <Typography variant="body1" gutterBottom>
            With InviteOnly you can earn money by offering paid groups and subscriptions to your Telegram chat groups or video calls and webinars using your favorite tool (Zoom, Google Meet, WebEx, etc).
          </Typography>
          <Typography variant="body1" gutterBottom>
            You can also earn 15% commission fees if you drive new subscribers to other groups.
          </Typography>

          <div className={classes.newBtn}>
            <Button
              color="primary" variant="outlined"
              component={ButtonLink}
              to="/dashboard"
            >
              Earn
            </Button>
          </div>
        </div>
      </div>
    </div>

  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    loggingIn: state.loggingIn,
    loggedIn: state.loggedIn,
    profile: state.profile,
    username: state.session.username || "",
    memberships: state.memberships,
    isFetching: state.isFetchingGroups,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateUsername: username => {
      dispatch(updateSession({username: username}));
    },
    load: () => dispatch(fetchMemberships()),
  }
}

const HomePage = connect(mapStateToProps, mapDispatchToProps)(InnerHomePage);
export default HomePage;
