import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { Redirect, Link } from 'react-router-dom';
import { fetchOwnGroups, updateSession } from '../store/actions';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormGroup from '@material-ui/core/FormGroup';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { ButtonLink, CenteredCircularProgress } from '../utils/utils';

const useStyles = makeStyles({
  hero: {
    padding: "16px 0px",
  },
  form: {
    margin: "16px 0px",
  },
  groupBtn: {
    marginTop: 16,
  },
  submitBtn: {
    marginLeft: 24,
    position: "relative",
    top: 24,
  },
});

function InnerSelector(props) {
  const classes = useStyles();
  const urlParams = new URLSearchParams(window.location.search);
  const chatId = urlParams.get('chat_id');
  if(chatId) props.updateChatId(chatId);

  useEffect( () => {
    props.loadGroups();
  }, []);

  if(props.loggingIn || !props.profile || !props.groups) return (
    <CenteredCircularProgress />
  );

  if(!chatId) {
    return (
      <div className="feed">
        <Typography variant="body1">
          You should land on this page only after clicking on a link on a Telegram group.
        </Typography>
        <ol>
          <li>Please create a group on Telegram</li>
          <li>Add <b>InviteOnlyBot</b> to your group</li>
          <li>Type <code>/new</code> and click on the link you will receive on Telegram</li>
        </ol>
      </div>
    );
  }

  let groups;

  if(props.groups) {
    // console.log(`Profile: ${JSON.stringify(props.profile)}`);
    let filteredGroups = Object.values(props.groups);
    filteredGroups = filteredGroups
        .filter( x => x.user_id===props.profile.user_id )  // only own groups
        .filter( x => x.group_type==="telegram" )          // only chat groups
        .filter( x => !x.chat_id );                        // only inactive

    if(filteredGroups.length===0) {
      groups = (
        <Typography variant="body1">
          Please <Link to="/dashboard">create a group first &raquo;</Link>
        </Typography>
      );

    } else if(filteredGroups.length===1) {
      return <Redirect to={`/groups/${filteredGroups[0].group_id}/activate?chat_id=${chatId}`} />
    } else {
      groups = filteredGroups.map( (group, i) => {
        let price = null;
        let period = null;
        if(group.membership==="lifetime") period = "one-off";
        else if(group.membership==="yearly") period = "/year";
        else if(group.membership==="monthly") period = "/month";

        if(group.price_cents && !isNaN(group.price_cents)) {
          price = `${group.currency.toUpperCase()} ${(group.price_cents/100.0).toFixed(2)} ${period}`;
        } else {
          price = 'Free';
        }
        return(
          <div className={classes.groupBtn}>
            <Button key={`button-group-${i}`} variant="contained" color="default" component={ButtonLink} size="large"
                to={`/groups/${group.group_id}/activate?chat_id=${chatId}`}
            >
              {group.name} <br/> {price}
            </Button>
          </div>
        );
      });

    }

  }

  return (
    <div className="feed">
      <h1>Activate group</h1>
      <p>Select the group to activate</p>

      {groups}

    </div>

  );
}

const mapStateToProps = (state, ownProps) => {

  return {
    loggingIn: state.loggingIn,
    loggedIn: state.loggedIn,
    profile: state.profile,
    groups: state.groups,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    loadGroups: () => {
      console.log(`Loading groups`);
      dispatch(fetchOwnGroups());
    },
    updateChatId: chatId => {
      dispatch(updateSession({telegramChatId: chatId}));
    },

  }
}

const ActivateSelector = connect(mapStateToProps, mapDispatchToProps)(InnerSelector);
export default ActivateSelector;
