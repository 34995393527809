import React from 'react';
import { connect } from "react-redux";
import { saveGroup, fetchGroup, } from '../store/actions';

import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';

import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import InputAdornment from '@material-ui/core/InputAdornment';
import AddIcon from '@material-ui/icons/Add';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import CameraAltIcon from '@material-ui/icons/CameraAlt';

import { 
  ButtonLink,
  currencies, getCurrency, getCurrencyName,
  ScrollToTop,
} from '../utils/utils';


import moment from 'moment';

const styles = theme => ({
  section: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  textField: {
    marginRight: 16,
  },
  formControl: {
    marginTop: 16,
  },
  formControlLeft: {
    marginTop: 16,
    marginRight: 8,
  },
});

const defaultGroup = {
  published: true,
  group_type: "telegram",
  name: "",
  description: "",
  membership: "free",
  price: "",
  currency: "USD",
  price_cents: 0,
  member_limit: "unlimited",
  max_members: 0,
  url: "",
};

class ImageCaptureButton extends React.PureComponent {
  render() {
    const { classes } = this.props;
    return (
      <Button variant="contained" component="label">
        <CameraAltIcon className={classes.leftIcon} />
        Group Image
        <input id="capture-image" 
          type="file" accept="image/*"
          style={{ display: 'none' }}
          onChange={this.props.handleFiles}
        />
      </Button>
    );
  }
}

ImageCaptureButton = withStyles(styles)(ImageCaptureButton);

class InnerGroup extends React.PureComponent {
  constructor(props) {
    super(props);


    let reformatGroup = null;
    if(this.props.group) {
      reformatGroup = {
        ...this.props.group,
        description: this.props.group.description || "",
        url: this.props.group.url || "",
      }
    }

    this.state = {
      images: [],
      group: reformatGroup || {...defaultGroup},
      lastSave: null,
    }
  }

  handleFiles = (e) => {
    this.setState({images: e.target.files});
    console.log(`Adding files ${JSON.stringify(e.target.files)}`);
  };

  handleChange = (e, key, value=undefined, key2=null) => {
    let group = {...this.state.group};
    if(key2) {
      group[key][key2] = (value!==undefined) ? value : e.target.value;
    } else {
      group[key] = (value!==undefined) ? value : e.target.value;
    }
    this.setState({ group: group });
  };

  handleSave = e => {
    e.preventDefault();
    let now = Date.now();
    this.setState({lastSave: now});
    let price_float = parseFloat(this.state.group.price);
    if(!isNaN(price_float)) {
      let price_cents = Math.round(parseFloat(this.state.group.price) * 100.0);
      this.state.group.price_cents = price_cents;
    }
    this.props.save(this.state.group, this.state.images); 
    //this.props.history.goBack();
    this.props.history.push('/dashboard');
  };


  render() {
    const { classes } = this.props;
    var title = "Add a new group";
    var intro = "Fill in the form below to add your private group."
    if(this.props.group) {
      title = "Edit class";
      intro = "Fill in the form below to update your private group.";
    }
    var period = "";
    if(this.state.group.membership==="lifetime") period = "one-off";
    else if(this.state.group.membership==="yearly") period = "/year";
    else if(this.state.group.membership==="monthly") period = "/month";

    // console.log(`Group published ${this.state.group.published}`);

    return (
      <div className="feed" style={{textAlign: "left"}}>
        <ScrollToTop />
        <h1>{title}</h1>
        <Typography variant="body2" color="textSecondary">
          {intro}
        </Typography>

        <form noValidate autoComplete="off" onSubmit={this.handleSave}>

          <FormControlLabel
            control={
              <Switch
                checked={this.state.group.published}
                onChange={ e => this.handleChange(e, "published", !this.state.group.published) }
                value="published"
                color="primary"
              />
            }
            label="Published"
          />

          <FormGroup>
            <FormControl component="fieldset">
              <FormLabel component="legend">Group type</FormLabel>
              <RadioGroup aria-label="Group Type" name="group_type"
                  value={this.state.group.group_type} 
                  onChange={ e => this.handleChange(e, "group_type") }
              >
                <FormControlLabel value="telegram" control={<Radio />} label="Telegram Group or Channel" />
                <FormControlLabel value="url" control={<Radio />} label="Private URL (e.g. Zoom or Google Meet)" />
              </RadioGroup>
  
            </FormControl>

          </FormGroup>

          <TextField
            label="Name"
            name="name"
            required={true}
            className={classes.textField}
            value={this.state.group.name}
            onChange={ e => this.handleChange(e, "name") }
            margin="normal"
            fullWidth
          />

          <TextField
            label="Description"
            name="description"
            className={classes.textField}
            value={this.state.group.description}
            onChange={ e => this.handleChange(e, "description") }
            margin="normal"
            multiline={true}
            rows="3"
            fullWidth
          />

          <TextField
            label="URL"
            name="url"
            type="url"
            helperText="Enter your private URL, e.g. https://us02web.zoom.us/j/123456?pwd=xyz or meet.google.com/xyz-abc"
            className={classes.textField}
            disabled={this.state.group.group_type!=="url"}
            value={this.state.group.url}
            onChange={ e => this.handleChange(e, "url") }
            margin="normal"
            fullWidth
          />

          <FormGroup>
            <FormControl component="fieldset">
              <FormLabel component="legend">Membership</FormLabel>
              <RadioGroup aria-label="Membership" name="membership"
                  value={this.state.group.membership} 
                  onChange={ e => this.handleChange(e, "membership") }
              >
                <FormControlLabel value="free" control={<Radio />} label="Free" />
                <FormControlLabel value="monthly" control={<Radio />} label="Monthly" />
                <FormControlLabel value="yearly" control={<Radio />} label="Yearly" />
                <FormControlLabel value="lifetime" control={<Radio />} label="Lifetime (one-off)" />
              </RadioGroup>
  
            </FormControl>

          </FormGroup>

          <FormGroup row>
            <FormControl className={classes.formControl}>
              <InputLabel>Currency</InputLabel>
              <Select
                native
                name="currency"
                disabled={this.state.group.membership==="free"}
                value={this.state.group.currency}
                onChange={ e => this.handleChange(e, "currency") }
              >
                {currencies.map( (c, i) => (<option key={`${c}_${i}`} value={c}>{getCurrencyName(c)}</option>) )}
              </Select>
            </FormControl>
            <TextField
              label="Price"
              name="price"
              type="number"
              className={classes.textField}
              disabled={this.state.group.membership==="free"}
              value={this.state.group.price}
              onChange={ e => this.handleChange(e, "price") }
              margin="normal"
              InputProps={{
                startAdornment: <InputAdornment position="start">{this.state.group.currency}</InputAdornment>,
                endAdornment: <InputAdornment position="end">{period}</InputAdornment>
              }}
            />
          </FormGroup>

          <FormGroup row>
            <FormControl component="fieldset" className={classes.formControlLeft}>
              <FormLabel component="legend">Set a maximum number of members</FormLabel>
              <RadioGroup aria-label="Maximum members" name="member_limit"
                  value={this.state.group.member_limit} 
                  onChange={ e => this.handleChange(e, "member_limit") }
              >
                <FormControlLabel value="max" control={<Radio />} label="Max" />
                <FormControlLabel value="unlimited" control={<Radio />} label="Unlimited" />
              </RadioGroup>
  
            </FormControl>

            <TextField
              label="Max members"
              name="max_members"
              type="number"
              helperText="Maximum number of active members accepted"
              className={classes.textField}
              disabled={this.state.group.member_limit==="unlimited"}
              value={this.state.group.max_members}
              onChange={ e => this.handleChange(e, "max_members") }
              margin="normal"
            />
          </FormGroup>

              

          {/*
          <FormGroup>
            <FormControl className={classes.formControl}>
              <ImageCaptureButton handleFiles={ this.handleFiles } />
              <FormHelperText>{ this.state.images && Object.values(this.state.images).map(x => x.name).join(", ") }</FormHelperText>
            </FormControl>
          </FormGroup>
          */}

          <div className={classes.section}>
            <Button variant="contained" color="primary" className={classes.button}
                    type="submit" disabled={this.props.isSaving}>
              Save
            </Button>

            &nbsp;

            <Button variant="outlined" component={ButtonLink} to="/dashboard">
              Back
            </Button>
          </div>
        </form>
        
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    groups: state.groups,
    profile: state.profile,
    user: state.user,
    isLoggedIn: state.loggedIn,
    isLoggingIn: state.loggingIn,
    isFetchingProfile: state.isFetchingProfile,
    isFetchingGroups: state.isFetchingGroups,
    isSaving: state.saving,
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    save: (obj, images) => {
      dispatch(saveGroup(obj, images));
    },
  }
}
const Group = connect(mapStateToProps, mapDispatchToProps)(InnerGroup);
export default withStyles(styles)(Group);

