import React from 'react';
import { connect } from "react-redux";
import {
  stripeConfirmCardSetup, stripeConfirmCardPayment, stripeConfirmCardSubscription, receiveProfile,
} from "../store/actions";

import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js';

import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Alert from '@material-ui/lab/Alert';
import LinkIcon from '@material-ui/icons/Link';

import CardSection from './CardSection';
import moment from 'moment';
import { ScrollToTop, ButtonLink, pluralize, CenteredCircularProgress } from '../utils/utils';

const styles = theme => ({
  section: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  priceTag: {
    fontWeight: "bold",
    background: "#fc9898",
    padding: 4,
  },
  recap: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  submitBtn: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    width: "100%",
    textAlign: "center",
  },
  success: {
    padding: theme.spacing(2),
  },
  failure: {
    padding: theme.spacing(2),
  },
  nextSteps: {
    marginTop: 40,
  },
  paying: {
    textAlign: "center",
  }
});

function CardSetupForm(props) {
  const { classes, group } = props;
  const [existingCard, setExistingCard] = React.useState(null);
  const [canSubmit, setCanSubmit] = React.useState(false);
  const [cardValidation, setCardValidation] = React.useState(null);

  const stripe = useStripe();
  const elements = useElements();

  React.useEffect(() => {
    if(elements) {
      const cardElement = elements.getElement(CardElement);

      if(cardElement) {
        cardElement.on('change', function(event) {
          if (event.complete) {
            // enable payment button
            setCanSubmit(true);
            setCardValidation(null);
          } else if (event.error) {
            // show validation to customer
            console.log(JSON.stringify(event.error));
            setCanSubmit(false);
            setCardValidation(event.error.message);
          }
        });
      }
    }

  }, []);


  const handleChange = (event, key) => {
    if(event) event.preventDefault();
    props.updateProfile(props.profile, {[key] : event.target.value});
  }

  const handleSubmit = (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    let pm = {
      card: elements.getElement(CardElement),
      billing_details: {
        name: props.profile.name,
        email: props.profile.email,
        phone: props.profile.phone,
      },
    };

    if(existingCard==='new' || (!existingCard && !props.defaultCard)) {
      console.log("Submitting new card details");
    } else {
      pm = existingCard || props.defaultCard;
      console.log(`Using existing card ${pm}`);
    }

    if(group && group.membership=='lifetime') {
      console.log(`CardSetupForm.handleSubmit: confirming payment on group ${group.name}, membership ${group.membership}`);

      props.cardPayment(stripe, props.secret, {
        receipt_email: props.profile.email,
        save_payment_method: true,
        payment_method: pm,
      });
    } else {
      if(group) {
        console.log(`CardSetupForm.handleSubmit: setting up subscription on ${group.name}, membership ${group.membership}`);
        props.cardSubscription(stripe, props.secret, { payment_method: pm }, group);
      } else {
        console.log("CardSetupForm.handleSubmit: no group, saving credit card");
        props.saveCard(stripe, props.secret, { payment_method: pm });
      }

    }

  };

  const changeCard = (event) => {
    event.preventDefault();
    setExistingCard(event.target.value);
  }

  let paymentMethods = null;
  if(props.paymentMethods) {
    paymentMethods = (
      <FormControl component="fieldset">
        <FormLabel component="legend">Charge your credit card</FormLabel>
        <RadioGroup aria-label="credit card" name="existing_credit_card" value={existingCard || props.defaultCard} onChange={changeCard}>
          {props.paymentMethods.map( (card, i) => (
            <FormControlLabel key={`card-${i}`}
              value={card.id}
              label={`${card.card.brand.toUpperCase()} *${card.card.last4} (Expires ${card.card.exp_month}/${card.card.exp_year})`} 
              control={<Radio />}
            />

          ))}

          <FormControlLabel key="card-new" value="new" label="Enter new credit card" control={<Radio />} />
        </RadioGroup>
      </FormControl>
    );
  }

  let recap = null;
  if(group) {
    let price = null;
    let period = null;
    if(group.membership==="lifetime") period = "one-off";
    else if(group.membership==="yearly") period = "/year";
    else if(group.membership==="monthly") period = "/month";

    if(group.price_cents && !isNaN(group.price_cents)) {
      price = `${group.currency.toUpperCase()} ${(group.price_cents/100.0).toFixed(2)} ${period}`;
    } else {
      price = 'Free';
    }
    let subtitle = group.description;
    recap = (
      <div className={classes.recap}>
        <Typography variant="body1" gutterBottom>
          {group.name}
        </Typography>
        <Typography variant="body1" color="textSecondary" gutterBottom>
          {subtitle}
        </Typography>
        <div style={{textAlign: "center"}}>
          <span className={classes.priceTag}>{price}</span>
        </div>
      </div>
    );
  }

  if(props.paymentMessage) console.log(`paymentMessage: ${JSON.stringify(props.paymentMessage)}`);

  if(props.paymentSuccessful)
    return (
      <div className={classes.success}>
        <ScrollToTop />
        <Alert severity="success">
          Payment successful!
        </Alert>

        <div className={classes.nextSteps}>
          <Typography variant="body1">
            Thanks for your purchase! Go ahead and join the group on Telegram by clicking on the button below.
          </Typography>
          <div className={classes.submitBtn}>
            <Button  color="primary" variant="contained" startIcon={<LinkIcon />}
                component={ButtonLink}
                to={`/groups/${group.group_id}/join`}
              >
              Join Now!
            </Button>
          </div>
        </div>
      </div>
    );

  return (
    <form onSubmit={handleSubmit}>
      <ScrollToTop />
      { props.paymentFailed &&
        <div className={classes.failure}>
          <Alert severity="error">
            Payment failed: {props.paymentMessage ? JSON.stringify(props.paymentMessage) : ""}
          </Alert>
        </div>
      }

      <TextField
        className={classes.section}
        label="Name"
        required={false}
        value={props.profile.name} 
        onChange={ e => handleChange(e, "name") }
        fullWidth 
      />
      <TextField
        className={classes.section}
        label="Email"
        required={false}
        type="email"
        value={props.profile.email} 
        onChange={ e => handleChange(e, "email") }
        fullWidth 
      />


      {paymentMethods}
      
      <CardSection className={classes.section} />

      { cardValidation &&
        <div className={classes.failure}>
          <Alert severity="error">
            {cardValidation}
          </Alert>
        </div>
      }


      {recap} 

      <div className={classes.submitBtn}>
        <Button size="large" type="submit" color="primary" variant="contained"
            disabled={!stripe || !props.secret || props.isPaying || (existingCard==='new' && !canSubmit)}>
          Buy Now
        </Button>
      </div>

      <div className={classes.paying}>
        {props.isPaying &&

          <div>
            <CenteredCircularProgress />
            <Typography variant="body1" gutterBottom>
              Processing payment...
            </Typography>
          </div>
        }

      </div>
    </form>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    profile: state.profile,
    secret: state.stripe_client_secret,
    paymentMethods: state.stripe_payment_methods,
    defaultCard: state.stripe_default_payment_method,
    isPaying: state.paymentStatus === "submitted",
    paymentSuccessful: state.paymentStatus === "success",
    paymentFailed: state.paymentStatus === "failed",
    paymentMessage: state.paymentMessage,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    saveCard: (stripe, secret, data) => dispatch(stripeConfirmCardSetup(stripe, secret, data)),
    cardPayment: (stripe, secret, data) => dispatch(stripeConfirmCardPayment(stripe, secret, data)),
    cardSubscription: (stripe, secret, data, group) =>
        dispatch(stripeConfirmCardSubscription(stripe, secret, data, group)),
    updateProfile: (profile, update) => {
      if(update) {
        const updatedProfile = Object.assign(profile || {}, update);
        dispatch(receiveProfile(updatedProfile));
      }
    },
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(CardSetupForm));
