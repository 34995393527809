import React from 'react';
import { connect } from "react-redux";
import {
  saveProfile, fetchOwnGroups, apiRequest, connectStripeAccount,
  connectTelegramAccount, getStripeExpressLink,
} from '../store/actions';

import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import NativeSelect from '@material-ui/core/NativeSelect';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Alert from '@material-ui/lab/Alert';

import { Helmet } from 'react-helmet';

import { ButtonLink, STRIPE_CLIENT_ID, CenteredCircularProgress } from '../utils/utils';
import { v4 as uuid } from 'uuid';
import GroupPreview from '../groups/GroupPreview';


const styles = theme => ({
  section: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
});

class InnerDashboard extends React.PureComponent {
  constructor(props) {
    super(props);
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const username = urlParams.get('username');
    const telegram_id = urlParams.get('telegram_id');

    this.state = {
      userName: (props.profile && props.profile.name) ? props.profile.name : "",
      userEmail: (props.profile && props.profile.email) ? props.profile.email : "",
      userDescription: (props.profile && props.profile.description) ? props.profile.description: "",
      userSlug: (props.profile && props.profile.slug) ? props.profile.slug : (username || ""),
      validateSlug: {
        error: false,
        message: "",
      },
    };
    if(code) this.props.postCode(code);
    if(telegram_id) this.props.postTelegramId(telegram_id);
  }

  componentDidMount() {
    this.props.loadDashboard();
  }

  handleChange = (e, key, value=undefined) => {
    this.setState({ [key]: (value!==undefined) ? value : e.target.value });
  };

  handleSave = e => {
    e.preventDefault();
    let now = Date.now();
    this.setState({lastSave: now});
    this.props.saveUserProfile({
      name: this.state.userName,
      email: this.state.userEmail,
      description: this.state.userDescription,
      slug: this.state.userSlug,
    }); 
  };

  validate = () => {
    const slug = this.state.userSlug ? this.state.userSlug.trim() : null;
    if(!slug || slug.length==0) {
      this.setState({ validateSlug: { error: true, message: "Enter a URL" } });
      return true;
    }
    if(slug.length<6 || slug.length>30) {
      this.setState({ validateSlug: { error: true, message: "Your URL should be 6 to 30 characters long" } });
      return false;
    }
    const pattern = /^[a-zA-Z0-9_]+$/;
    if(!pattern.test(slug)) {
      this.setState({
        validateSlug: { error: true, message: "Only letters, numbers and underscore _ allowed" } 
      });
      return false;

    }
    apiRequest(`/shortcodes/${slug}`)
      .then( json => {
        console.log(`Found slug ${slug}, received json ${JSON.stringify(json)}`);
        if(json.data.user) {
          if(json.data.user.user_id != this.props.profile.user_id) {
            this.setState({
              validateSlug: { error: true, message: `${slug} is already taken` }
            });
          } else {
            console.log(`Slug ${slug} is owned by current user ${this.props.profile.user_id}`);
          }
        }
      })
      .catch(function(error) {
        console.log("Could not fetch shortcode due to exception");
        console.log(error);
        if(error.response && error.response.status===404) {
          console.log(`Slug ${slug} is available`);
        }
      });

    this.setState({ validateSlug: { error: false, message: "" } });
    return true;

  }

  render() {
    const { classes, profile } = this.props;

    var groups = (
      <Typography variant="body1">
        Add your groups to get started.
      </Typography>
    );

    if(this.props.groups) {
      console.log(`Profile: ${JSON.stringify(profile)}`);
      let filteredGroups = Object.values(this.props.groups);
      filteredGroups = filteredGroups
          .filter( x => x.user_id===this.props.profile.user_id )
          .filter( x => x.status!=="deleted" );
      // sort by novelty
      filteredGroups.sort( (d1, d2) => -(d1.created_at - d2.created_at) );
      groups = filteredGroups
        .map( (group, i) => {
          console.log(`Group ${group.group_id} user ${group.user_id}, profile user_id ${profile.user_id}`);
          return <GroupPreview group={group} user_id={this.props.profile.user_id} key={`group-${i}`} />
        }
      );

    }

    const activateProfile = (
      <Alert severity="warning"
        action={
          <Button
              color="inherit"
              href={`https://t.me/InviteOnlyBot?start=${profile.activation_code}`}
          >
            Activate
          </Button>
        }
      >
        Activate your account by connecting to our <code>InviteOnlyBot</code> Telegram bot.
      </Alert>
    );
    const isActivated = profile.metadata && profile.metadata.telegram_id;

    const token = uuid();
    const nameWords = (this.state.userName && this.state.userName.length>0)
      ? this.state.userName.split(' ') : [];

    const firstName = nameWords.length>0 ? nameWords[0] : "";
    const lastName  = nameWords.length>1 ? nameWords[nameWords.length-1] : "";

    const dashboardLink = (
      <Button 
          component={ButtonLink} 
          to="/express_redirect" 
          variant="outlined" 
          onClick={ e => this.props.getLink()}
      >
        Payment Dashboard
      </Button>
    );
    const connectLink = profile ? (
      <span><a href={`https://connect.stripe.com/express/oauth/authorize?redirect_uri=https://inviteonly.gg/dashboard&client_id=${STRIPE_CLIENT_ID}&state=${token}&stripe_user[email]=${this.state.userEmail}&stripe_user[phone_number]=${this.props.profile.phone}&stripe_user[url]=https://inviteonly.gg/user/${this.props.profile.user_id}&stripe_user[first_name]=${firstName}&stripe_user[last_name]=${lastName}`}>Enter your details</a> to receive your payments.</span>
    ) : null;

    const getPaid =
      (this.props.profile && this.props.profile.metadata && this.props.profile.metadata.stripe_connect_id)
      ? dashboardLink
      : connectLink;

    return (
      <div className="feed" style={{textAlign: "left"}}>
        <Helmet>
          <title>InviteOnly Dashboard</title>
          <meta name="description" content="InviteOnly Dashboard allows you to manage your groups." />
        </Helmet>
        <h1>Dashboard</h1>
        <Typography variant="body1" color="textSecondary">
          Manage your profile and groups
        </Typography>

        <h2>Your profile</h2>
        {!isActivated && activateProfile}

        <form noValidate autoComplete="off" onSubmit={this.handleSave}>

          <TextField
            label="Username"
            className={classes.textField}
            value={this.state.userSlug}
            onChange={ e => this.handleChange(e, "userSlug") }
            margin="normal"
            helperText={this.state.validateSlug.error ? this.state.validateSlug.message : "Pick your personal URL"}
            InputProps={{
              startAdornment: <InputAdornment position="start">InviteOnly.gg/</InputAdornment>,
            }}
            onBlur={this.validate}
            error={this.state.validateSlug.error}
            fullWidth
          />

          
          <TextField
            label="Name"
            name="name"
            required={true}
            className={classes.textField}
            value={this.state.userName}
            onChange={ e => this.handleChange(e, "userName") }
            margin="normal"
            fullWidth
          />

          <TextField
            label="Email"
            name="email"
            type="email"
            required={true}
            className={classes.textField}
            value={this.state.userEmail}
            onChange={ e => this.handleChange(e, "userEmail") }
            margin="normal"
            fullWidth
          />

          <TextField
            label="Description"
            name="description"
            helperText="Tell us something about you"
            required={false}
            className={classes.textField}
            value={this.state.userDescription}
            onChange={ e => this.handleChange(e, "userDescription") }
            margin="normal"
            multiline={true}
            rows="3"
            fullWidth
          />

          <Button variant="contained" color="primary" className={classes.button}
                  type="submit" disabled={this.props.isSaving}>
            Save
          </Button>

        </form>

        <h2>Get paid</h2>
        <Typography variant="body1" gutterBottom>
          {getPaid}
        </Typography>

        <Typography variant="body1" gutterBottom>
          You can make money in two ways:
        </Typography>

        <ol>
          <li>Create paid chat groups below and collect subscription fees from your members</li>
          <li>
            Refer other groups and earn a 15% revenue commission
            <React.Fragment>
              {this.props.profile && this.props.profile.slug && this.props.profile.slug.length>0 ? <React.Fragment> by adding <code>?ref={this.props.profile.slug}</code> to any InviteOnly.gg link.</React.Fragment> : <React.Fragment>. Pick your username above to show your referral code.</React.Fragment>}
            </React.Fragment>
          </li>
        </ol>

        <h2>Groups</h2>
        <Button variant="outlined" startIcon={<AddIcon />} component={ButtonLink} to="/groups/new">
          Add a group
        </Button>
        <div className={classes.section}>
          {(!this.props.groups && this.props.isFetchingGroups) ? <CenteredCircularProgress /> : groups}
        </div>

      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    profile: state.profile,
    user: state.user,
    groups: state.groups,
    isLoggedIn: state.loggedIn,
    isLoggingIn: state.loggingIn,
    isFetchingGroups: state.isFetchingGroups,
    isSaving: state.saving,
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    loadDashboard: () => {
      dispatch(fetchOwnGroups());
    },
    saveUserProfile: (profile) => {
      dispatch(saveProfile(profile));
    },
    postCode: code => dispatch(connectStripeAccount(code)),
    postTelegramId: tId => dispatch(connectTelegramAccount(tId)),
    getLink: () => dispatch(getStripeExpressLink()),
  }
}
const Dashboard = connect(mapStateToProps, mapDispatchToProps)(InnerDashboard);
export default withStyles(styles)(Dashboard);

