import React from 'react';
import {
  Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { connect } from "react-redux";

import './App.css';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core/styles';

import WebFont from 'webfontloader';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';


import NavBar from './nav/NavBar';
import StatusMessage from './nav/StatusMessage';
import NotFound from './utils/NotFound';
import Signin from './auth/Signin';
import Signout from './auth/Signout';
import HomePage from './home/Home';
import Dashboard from './home/Dashboard';
import CreateGroup from './home/CreateGroup';
import Interstitial from './home/Interstitial';
import GroupPage from  './groups/GroupPage';
import GroupJoin from  './groups/GroupJoin';
import ActivateGroup from './groups/ActivateGroup';
import ActivateSelector from './groups/ActivateSelector';
import UserPage from './user/UserPage';
import CardForm from './pay/CardForm';
import Footer from './nav/Footer';

import { CenteredCircularProgress } from './utils/utils';

const history = createBrowserHistory();

/* Loading fonts */
WebFont.load({
  google: {
    families: ['Lora:400']
  }
});

ReactGA.initialize('UA-102687-28');

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  palette: {
    primary: { main: '#c62828' },
    secondary: {main: '#3949ab' },
  },
});

// theme.typography.h4.fontFamily = ["Montserrat", "-apple-system", '"Segoe UI"', "Roboto"].join(',');


const InnerPrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => {
    if(rest.isLoggingIn === true) return (
      <CenteredCircularProgress />
    );

    if(rest.isLoggedIn === true) {
      if(rest.profile !== null) return <Component {...props} />;
      else return <CenteredCircularProgress />;
    }

    let loginUrl = `/login?next=${encodeURIComponent(window.location.href)}`;

    return <Redirect to={loginUrl} />;
  }} />
);


class InnerApp extends React.PureComponent {
  constructor() {
    super();
    // console.log("Landing page firing");
    ReactGA.pageview(window.location.pathname + window.location.search);
    ReactPixel.pageView();
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const ref = urlParams.get('ref');
    console.log(`Found referral ${ref}`);
    if(ref && ref.length>0) {
      // TODO: should drop cookie
      const refCookie = `${ref}|${Date.now()}`;
      document.cookie = `ref=${encodeURIComponent(refCookie)};path='/';max-age=${60*60*24*30}`;
    }

  }

  render() {
    return (
      <Router history={history}>
        <div className="App">
          <ThemeProvider theme={theme}>
            <NavBar />

            <div className="main">
              <Switch>
                <Route exact path="/" render={({history}) => <HomePage history={history} />} />
                <Route path="/login" render={({history}) => <Signin history={history} />} />
                <PrivateRoute path="/logout" component={Signout} />
                <PrivateRoute path="/activate" component={ActivateSelector} />
                <PrivateRoute path="/dashboard" component={Dashboard} />
                <PrivateRoute path="/groups/new" component={CreateGroup} />
                <PrivateRoute path="/groups/:groupId/pay" component={CardForm} />
                <PrivateRoute path="/groups/:groupId/activate" component={ActivateGroup} />
                <PrivateRoute path="/groups/:groupId/join" component={GroupJoin} />
                <Route path="/groups/:groupId" 
                    render={ ({history, match}) => <GroupPage history={history} match={match} />} />
                <Route path="/express_redirect"
                    render={ () => <Interstitial destination="Payment Dashboard" /> } />

                <Route path="/:slug"
                    render={ ({history, match}) => <UserPage history={history} match={match} />} />

                <Route render={() => <NotFound />} />
              </Switch>
            </div>

            <StatusMessage />
            <Footer />

          </ThemeProvider>

        </div>
      </Router>
    );
  }
}


const mapStateToProps = state => {
  return {
    user: state.user,
    profile: state.profile,
    isLoggedIn: state.loggedIn,
    isLoggingIn: state.loggingIn,
  };
}

const App = connect(mapStateToProps)(InnerApp);
const PrivateRoute = connect(mapStateToProps)(InnerPrivateRoute);

export default App;
