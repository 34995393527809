import React from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import {
    deleteGroup,
} from '../store/actions';

import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CardActionArea from '@material-ui/core/CardActionArea';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import TelegramIcon from '@material-ui/icons/Telegram';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckIcon from '@material-ui/icons/Check';

import moment from 'moment';
import { GCS_DOMAIN, ButtonLink, whatsappLink, telegramLink } from '../utils/utils';

const styles = theme => ({
  group: {
    margin: "0px 0px 16px",
    textAlign: "left",
  },
  groupContent: {
    display: "flex",
  },
  media: {
    margin: "16px 0px 0px 8px",
    width: 100,
    //height: 0,
    // paddingTop: '56.25%', // 16:9
  },
  details: {
    width: "100%",
  },
  denseCard: {
    padding: 8,
  },
  actions: {
    width: "100%",
    padding: "0px 0px 4px 4px",
    "& button": {
      marginRight: 8,
    },
  },
  shop: {
    marginLeft: "auto",
    marginRight: 16,
  },
  priceTag: {
    fontWeight: "bold",
    background: "#fc9898",
    padding: 4,
  },
  members: {
    color: theme.palette.text.secondary,
  },

});

class InnerGroupPreview extends React.PureComponent {
  handleDelete = (e, groupId) => {
    if(e) e.preventDefault();
    if(window.confirm("Are you sure you want to delete this group?")) this.props.deleteGroup(groupId);
  }

  render() {
    const { classes, group, user_id } = this.props;
    const isOwner = (group && user_id === group.user_id);
    // let image = "/logo.png";
    let media = (
      <Avatar>
        { (group.teacher && group.teacher.name) ? group.teacher.name[0].toUpperCase() : group.name[0].toUpperCase() }
      </Avatar>
    );
    if(group.thumb_urls && group.thumb_urls.length>0) {
      let image = GCS_DOMAIN + group.thumb_urls.split(',')[0];
      media = (<CardMedia image={image} title={group.name} component="img" />);
    }
    let price = null;
    let period = null;
    if(group.membership==="lifetime") period = "one-off";
    else if(group.membership==="yearly") period = "/year";
    else if(group.membership==="monthly") period = "/month";

    if(group.price_cents && !isNaN(group.price_cents)) {
      price = `${group.currency.toUpperCase()} ${(group.price_cents/100.0).toFixed(2)} ${period}`;
    } else {
      price = 'Free';
    }
    let subtitle = group.description;
    /*
    if(isOwner) {
      subtitle = `[${group.published ? "PUBLISHED" : "NOT PUBLISHED"}] ${subtitle}`;
    }*/

    let members = null;
    if(group.member_limit==="max" && group.max_members>0) {
      if(group.n_members && group.n_members>1)
        members = `${group.n_members} members (max ${group.max_members})`;
      else
        members = `Max ${group.max_members} members`;
    } else {
      if(group.n_members && group.n_members>1) members = `${group.n_members} members`;
    }

    subtitle = (
      <React.Fragment>
        {subtitle}<br/>
        <span className={classes.priceTag}>{price}</span><br/>
        <span className={classes.members}>{members}</span>
      </React.Fragment>
    );

    let activateBtn = null;
    if((!group.group_type || group.group_type==='telegram') && !group.chat_id) {
      activateBtn = (
        <Button className={classes.shop} color="secondary"
            aria-label="activate"
            component={ButtonLink}
            to={`/groups/${group.group_id}/activate`}
            startIcon={<CheckIcon />} >
          Activate
        </Button>
      );
    }

    return (
      <Card className={classes.group}>
        <div className={classes.groupContent}>
          <div className={classes.media}>
            <Link className="clean-link" to={`/groups/${group.group_id}`}>
              {media}
            </Link>
          </div>
          <div className={classes.details}>
            <CardActionArea>
              <Link className="clean-link" to={`/groups/${group.group_id}`}>
                <CardHeader className={classes.denseCard} title={group.name} subheader={subtitle} />
              </Link>
              <CardContent className={classes.denseCard} style={{paddingBottom: 8}}>
                {group.user && 
                  <Typography variant="body1" color="textSecondary" component="p">
                    by <b><Link to={`/${group.user.slug}`}>{group.user.name}</Link></b>
                  </Typography>}
              </CardContent>
            </CardActionArea>
          </div>
        </div>
        <CardActions className={classes.actions} disableSpacing>
          <IconButton aria-label="share on WhatsApp"
              href={whatsappLink(group.name, 'https://inviteonly.gg/groups/' + group.group_id)}
          >
              <WhatsAppIcon />
          </IconButton>
          <IconButton aria-label="share on Telegram"
              href={telegramLink(group.name, 'https://inviteonly.gg/groups/' + group.group_id)}
          >
              <TelegramIcon />
          </IconButton>

          <div className={classes.shop}>
            {isOwner && (
              <React.Fragment>
                {activateBtn}
                {/*
                <Button color="primary"
                    aria-label="edit"
                    component={ButtonLink}
                    to={`/dashboard/group/${group.group_id}`}
                    startIcon={<EditIcon />} >
                  Edit
                </Button>
                */}
                <Button color="primary"
                    aria-label="delete"
                    onClick={e => this.handleDelete(e, group.group_id)}
                    startIcon={<DeleteIcon />} >
                  Delete
                </Button>
              </React.Fragment>
            )}
            <Button color="primary"
                aria-label="learn more"
                component={ButtonLink} to={`/groups/${group.id}`} >
              Learn More
            </Button>
          </div>
        </CardActions>
      </Card>
    );
  }

}

const mapStateToProps = state => {
  return {
    profile: state.profile,
    user: state.user,
    groups: state.groups,
    isLoggedIn: state.loggedIn,
    isLoggingIn: state.loggingIn,
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    deleteGroup: (groupId) => dispatch(deleteGroup(groupId)),
  }
}

const GroupPreview = connect(mapStateToProps, mapDispatchToProps)(InnerGroupPreview);
export default withStyles(styles)(GroupPreview);
