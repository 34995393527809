import React from 'react';
import { connect } from "react-redux";
import { stripeIntent, fetchGroup, fetchGroups } from '../store/actions';

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {STRIPE_KEY} from '../utils/utils';
import CardSetupForm from './CardSetupForm';

import { withStyles } from '@material-ui/core/styles';

const stripePromise = loadStripe(STRIPE_KEY);

const styles = theme => ({
});

class InnerCardForm extends React.PureComponent {
  componentDidMount() {
    this.props.setupIntent();
    if(!this.props.group) {
      this.props.loadGroup();
    }
  }

  render() {
    return (
      <div className="feed" style={{textAlign: "left"}}>
        <Elements stripe={stripePromise}>
          <CardSetupForm group={this.props.group} />
        </Elements>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { groupId } = ownProps.match.params;

  let group = null;
  if(groupId && state.groups && groupId in state.groups) group = state.groups[groupId];
  return {
    profile: state.profile,
    group: group,
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  let gid = null;
  if(ownProps.match && ownProps.match.params) gid = ownProps.match.params.groupId;
  return {
    setupIntent: () => {
      dispatch(stripeIntent(gid));
    },
    loadGroup: () => {
      console.log(`Loading Group ${gid}`);
      dispatch(fetchGroup(gid));
      setTimeout(() => dispatch(fetchGroups()), 500);
    },
  }
}
const CardForm = connect(mapStateToProps, mapDispatchToProps)(InnerCardForm);
export default withStyles(styles)(CardForm);
