import React from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { ButtonLink } from '../utils/utils';

function NavBar(props) {
    const { profile, isLoggedIn, isLoggingIn } = props;

    const isActivated = profile && profile.metadata && profile.metadata.telegram_id;
    const activateBtn = profile ? (
        <Button
            variant="contained" color="secondary"
            style={{marginRight: 8}}
            href={`https://t.me/InviteOnlyBot?start=${profile.activation_code}`}
        >
          Activate
        </Button>
    ) : null;

    let loginBtn = null;
    if(!isLoggingIn) {
      if(isLoggedIn) {
        loginBtn = (
            <Button
              variant="outlined"
              color="primary"
              component={ButtonLink}
              to="/dashboard"
            >
              Earn
            </Button>
        );

      } else {
        loginBtn = (
            <Button
              variant="outlined"
              color="primary"
              component={ButtonLink}
              to="/dashboard"
            >
              Login
            </Button>
        );

      }
    }

    return (
      <nav className="navbar">
        <div className="feed" style={{textAlign: "left", display: "flex" }}>
          <div>
            <Link to="/" className="clean-link">
              <span className="logo">InviteOnly</span>
            </Link>
          </div>
          <div style={{ marginLeft: "auto" }}>
            {isLoggedIn && profile && !isActivated && activateBtn}
            {loginBtn}
          </div>
        </div>
      </nav>
    );
}


const mapStateToProps = (state, ownProps) => {

  return {
    isLoggedIn: state.loggedIn,
    isLoggingIn: state.loggingIn,
    profile: state.profile,
  };
}

export default connect(mapStateToProps)(NavBar);
