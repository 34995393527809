import {
  SIGNIN_EVENT, SAVE_TOKEN, RECEIVE_GEO, RECEIVE_PROFILE, REQUEST_PROFILE,
  UPDATE_SESSION,
  RECEIVE_OTHER_PROFILE,
  REQUEST_GROUPS, RECEIVE_GROUPS, RECEIVE_MEMBERSHIPS,
  REQUEST_GROUP_PAYMENTS, RECEIVE_GROUP_PAYMENTS,
  REQUEST_GROUP_ACTIVATION,
  RECEIVE_INVITE_LINK,
  RECEIVED_STRIPE_CLIENT_SECRET, RECEIVED_STRIPE_PAYMENT_METHODS,
  REDIRECT_EXPRESS,
  OPEN_STATUS_MESSAGE, CLOSE_STATUS_MESSAGE, SELECT_TAB,
  SAVING, UPDATE_PAYMENT_STATUS,
} from './actions';

const initialState = {
  selectedTab: "home",
  geo: null,
  language: null,
  user: null,
  session: {},
  profile: null,
  groups: {},
  memberships: {},
  loggedIn: false,
  loggingIn: true,
  token: null,
  statusMessage: null,
  openStatusMessage: false,
  isFetchingProfile: true,
  isFetchingGroups: false,
  isActivatingGroup: false,
  saving: false,
  paymentStatus: null,
  paymentMessage: null,
  stripe_client_secret: null,
  stripe_payment_methods: null,
  stripe_default_payment_method: null,
  redirectExpressUrl: null,
  inviteLinks: null,
};

const rootReducer = (state = initialState, action) => {
  switch(action.type) {
    case SIGNIN_EVENT:
      if(action.user) {
        console.log(`Signing in with user ${JSON.stringify(action.user)}`);
        /*
        let new_profile = {...state.profile};
        new_profile.name = new_profile.name || action.user.displayName;
        new_profile.email = new_profile.email || action.user.email;
        new_profile.number = new_profile.number || action.user.phoneNumber;
        return { ...state, loggedIn: true, loggingIn: false, user: action.user, profile: new_profile, };
        */
        return { ...state, loggedIn: true, loggingIn: false, user: action.user, };
      } else {
        console.log("User not logged in");
        return { ...state, loggingIn: false, loggedIn: false };
      }

    case SAVE_TOKEN:
      return { ...state, token: action.token };

    case UPDATE_SESSION:
      return { ...state, session: {...state.session, ...action.session}}

    case REQUEST_PROFILE:
      return { ...state, isFetchingProfile: true, };

    case RECEIVE_PROFILE:
      return { ...state, profile: {...action.profile }, isFetchingProfile: false, lastRefresh: Date.now()};

    case RECEIVE_OTHER_PROFILE:
      return { ...state, otherProfile: {...action.profile }, isFetchingProfile: false};

    case RECEIVE_GEO:
      return { 
          ...state, 
          geo: action.geo,
          // currency: (action.geo && action.geo.country) ? getCurrency(action.geo.country) : null,
          language: action.language 
      };

    case REQUEST_GROUPS:
      return { ...state, isFetchingGroups: true };

    case RECEIVE_GROUPS: {
      if(!action.groups) return { ...state, isFetchingGroups: false };

      let new_groups = {};
      for(let i=0; i<action.groups.length; i++) {
        new_groups[action.groups[i].id] = action.groups[i];
      }
      return {
        ...state,
        groups: {...state.groups, ...new_groups},
        isFetchingGroups: false,
      }
    }
    
    case REQUEST_GROUP_ACTIVATION:
      return { ...state, isActivatingGroup: action.activating }

    case RECEIVE_MEMBERSHIPS: {
      if(!action.groups) return { ...state, isFetchingGroups: false };

      let new_groups = {};
      for(let i=0; i<action.groups.length; i++) {
        new_groups[action.groups[i].id] = action.groups[i];
      }
      return {
        ...state,
        memberships: {...state.memberships, ...new_groups},
        isFetchingGroups: false,
      }
    }

    case REQUEST_GROUP_PAYMENTS:
      return { ...state, isFetchingGroupPayments: true };

    case RECEIVE_GROUP_PAYMENTS: {
      var groupPayments = {};
      if(!action.groupPayments || action.groupPayments.length===0)
        return {...state, isFetchingGroupPayments: false};

      for(let i=0; i<action.groupPayments.length; i++) {
        let gid = action.groupPayments[i].group_id;
        if(!groupPayments[gid]) groupPayments[gid] = [];
        groupPayments[gid].push(action.groupPayments[i]);
      }
      var newState = { ...state, groupPayments: Object.assign(state.groupPayments || {}, groupPayments), isFetchingGroupPayments: false, };
      return newState;
    }

    case RECEIVE_INVITE_LINK: {
      let inviteLinks = state.inviteLinks ? {...state.inviteLinks} : {}
      inviteLinks[action.groupId] = action.inviteLink;
      return {...state, inviteLinks};
    }

    case RECEIVED_STRIPE_CLIENT_SECRET:
      return { ...state, stripe_client_secret: action.secret };

    case RECEIVED_STRIPE_PAYMENT_METHODS:
      let default_pm = null;
      if(state.stripe_default_payment_method)
        default_pm = state.stripe_default_payment_method
      else if (action.paymentMethods && action.paymentMethods.length>0)
        default_pm = action.paymentMethods[0].id;

      return { ...state,
        stripe_payment_methods: action.paymentMethods,
        stripe_default_payment_method: default_pm };

    case REDIRECT_EXPRESS:
      return { ...state, redirectExpressUrl: action.url };

    case OPEN_STATUS_MESSAGE:
      return { ...state, openStatusMessage: true, statusMessage: action.message };

    case CLOSE_STATUS_MESSAGE:
      return { ...state, openStatusMessage: false, statusMessage: null };

    case SELECT_TAB:
      console.log(`Setting tab ${action.tab}`);
      return { ...state, selectedTab: action.tab }

    case SAVING:
      return { ...state, saving: action.isSaving };

    case UPDATE_PAYMENT_STATUS:
      return { ...state, paymentStatus: action.status, paymentMessage: action.message };

    default:
      return state;
  }
};

export default rootReducer;

