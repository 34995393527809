import React from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import {
  fetchGroups, fetchProfile, openStatusMessage,
} from '../store/actions';

import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import TelegramIcon from '@material-ui/icons/Telegram';

import moment from 'moment';
import { Helmet } from 'react-helmet';
import { GCS_DOMAIN, whatsappLink, telegramLink } from '../utils/utils';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';

import GroupPreview from '../groups/GroupPreview';
import { ScrollToTop, ButtonLink, pluralize, CenteredCircularProgress } from '../utils/utils';

const styles = theme => ({
  user: {

  },
  groups: {
    marginTop: 16,
  },
});


class InnerUserPage extends React.PureComponent {
  state = {
  };

  componentDidMount() {
    this.props.loadUser();
    ReactPixel.track("ViewContent", {
        content_ids: ["user_" + this.props.slug],
        content_type: "product",
        contents: [{id: "user_" + this.props.slug, quantity: 1}],
    });
  }

  render() {
    const { classes, user, groups, slug } = this.props;
    let card = (<CenteredCircularProgress />);
    let groupsObj = null;

    if(user && !this.props.isFetchingProfile) {

      groupsObj = (
        <Typography gutterBottom variant="body1" color="textSecondary">
          {user.name} hasn't created any group yet.
        </Typography>
      );

      var json_ld = {
          "@context": "http://schema.org",
          "@type": "Person",
          "name": user.name,
          "url": `https://inviteonly.gg/${slug}`,
      };

      if(groups) {
        // console.log(`Profile: ${JSON.stringify(this.props.profile)}`);
        let filteredGroups = Object.values(groups);
        if(filteredGroups.length>0) {
          filteredGroups = filteredGroups
              .filter( x => x.user_id===user.user_id )
              .filter( x => x.status!=="deleted" );
          // sort by novelty
          filteredGroups.sort( (d1, d2) => -(d1.created_at - d2.created_at) );
          groupsObj = filteredGroups
            .map( (group, i) => {
              return <GroupPreview group={group} key={`group-${i}`} />
            }
          );
        }

      }

      card=(
        <div className={classes.user}>
          <ScrollToTop />
          
          <Helmet>
            <title>{`${user.name || slug} | InviteOnly.gg`}</title>
            <meta name="description" content={user.description} />
            <script type="application/ld+json">
              {JSON.stringify(json_ld)}
            </script>
          </Helmet>

          <Typography variant="h4" gutterBottom>{user.name}</Typography>
          <Typography variant="body1" color="textSecondary">@{user.slug}</Typography>
          <Typography variant="body1" color="textSecondary">{user.description}</Typography>
          <div className={classes.actions}>
            <IconButton aria-label="share on WhatsApp"
                href={whatsappLink(user.name, window.location.href)}
            >
                <WhatsAppIcon />
            </IconButton>
            <IconButton aria-label="share on Telegram"
                href={telegramLink(user.name, window.location.href)}
            >
              <TelegramIcon />
            </IconButton>
          </div>
          <div className={classes.groups}>
            {(!this.props.groups && this.props.isFetchingGroups) ? <CenteredCircularProgress /> : groupsObj}
          </div>
        </div>
      );
    };

    return (
      <div className="feed">
        {card}
      </div>
    );
  }

}

const mapStateToProps = (state, ownProps) => {

  return {
    slug: ownProps.match.params.slug,
    groups: state.groups,
    user: state.otherProfile,
    ownProfile: state.profile,
    ownUser: state.user,
    isLoggedIn: state.loggedIn,
    isLoggingIn: state.loggingIn,
    isFetchingProfile: state.isFetchingProfile,
    isFetchingGroups: state.isFetchingGroups,
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    loadUser: () => {
      console.log(`Loading user ${ownProps.match.params.slug}`);
      dispatch(fetchProfile(ownProps.match.params.slug));
      dispatch(fetchGroups(ownProps.match.params.slug));
    },
  }
}
const UserPage = connect(mapStateToProps, mapDispatchToProps)(InnerUserPage);
export default withStyles(styles)(UserPage);
