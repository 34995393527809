import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import { createStore, applyMiddleware } from 'redux';
import rootReducer from './store/reducers';
import { Provider } from 'react-redux';
import thunkMiddleware from 'redux-thunk';

import App from './App';
import * as serviceWorker from './serviceWorker';

import firebase from 'firebase/app';
import 'firebase/messaging';

import {
  fetchGeo, firebaseLogin, firebaseTokenRefresh,
} from './store/actions';

const REFRESH_TOKEN_LOOP_MS = 20 * 60 * 1000;

/* Firebase Messaging Web Push Setup */
if (firebase.messaging.isSupported()) {
  const messaging = firebase.messaging();
  messaging.onMessage((payload) => {
    console.log('Push notification received. ', payload);
    //if(payload.data && payload.data.channel_id) {
    //  store.dispatch(fetchChannel(payload.data.channel_id));
    //}
  });
} else {
  console.log("Firebased messaging not supported by this browser");
}

/* Startup sequence */
const store = createStore(
  rootReducer,
  applyMiddleware(thunkMiddleware)
);

const refreshTokenLoop = () => {
  window.setInterval( () => store.dispatch(firebaseTokenRefresh()), REFRESH_TOKEN_LOOP_MS);
};


store.dispatch(fetchGeo());
store.dispatch(firebaseLogin());

ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
  document.getElementById('root')
);

/* Loops */
refreshTokenLoop();


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
