import React from 'react';
import { useEffect } from "react";
import { Link } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';

import country_currency from './currency.json';
// import country_names from './countries.json';
import currency_names from './currency_names.json';


export var GCS_DOMAIN = 'https://storage.googleapis.com';
export var STRIPE_KEY = 'pk_live_rXPfbXL80Ru5rDCjFbTM8gjw00F6jExooq';
export var STRIPE_CLIENT_ID = 'ca_HDoIIFC1rg1UMQgzT9qzmhbxfuQTR5nX'

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  // dev code
  GCS_DOMAIN = 'http://localhost:8080/_ah/gcs'; 
  STRIPE_KEY = 'pk_test_QCLB6O98cCw09AbQvfmjAggf00Rpm7iaUa';
  STRIPE_CLIENT_ID = 'ca_HDoI38z3SuRVSYqMIJFZyIWPiRXIBlZe';
  console.log(`Setting development vars stripe key ${STRIPE_KEY}`);
}

export const ButtonLink = React.forwardRef((props, ref) => <Link innerRef={ref} {...props} />);
export const whatsappLink = (text, url) => (`https://wa.me/?text=${encodeURIComponent(text+" "+url)}`);

export const telegramLink = (text, url) => (`https://t.me/share/url?url=${url}&text=${text}`);

export const CITIES = [
  { value: "singapore", display: "Singapore" },
  { value: "hong_kong", display: "Hong Kong" },
  { value: "kuala_lumpur", display: "Kuala Lumpur" },
  { value: "johor_bahru", display: "Johor Bahru" },
  { value: "jakarta", display: "Jakarta" },
  { value: "Bangkok", display: "Bangkok" },
  { value: "manila", display: "Manila" },
];

export const CATEGORIES = [
  { value: "arts", display: "Arts" },
  { value: "mother_tongue", display: "Mother Tongue" },
  { value: "life_skills", display: "Life Skills" },
  { value: "music", display: "Music" },
  { value: "social_studies", display: "Social Studies" },
  { value: "coding_tech", display: "Coding & Tech" },
  { value: "health_welness", display: "Health & Wellness" },
  { value: "math", display: "Math" },
  { value: "science_nature", display: "Science & Nature" },
  { value: "foreign_languages", display: "Foreign Languages" },
  { value: "religion", display: "Religion" },
  { value: "other", display: "Other" },
];


export function ScrollToTop() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return null;
}

export const main_currencies = [
  "USD", "CAD", "EUR", "CHF", "GBP", "SEK", "NOK", "JPY", "SGD", "HKD", "KRW", "MYR", "AUD", "NZD",
];

export const all_currencies = Array.from(
  new Set(Object.entries(country_currency).map( couple => couple[1] )).values())
    .filter(x => (x && x.length>0))
    .sort();

// export const currencies = main_currencies.concat(all_currencies);
export const currencies = main_currencies;

export const currency_data = {
  "USD": { xrate: 1.0, },
  "EUR": { xrate: 0.8923, },
  "GBP": { xrate: 0.8024, },
  "SGD": { xrate: 1.3619, },
  "HKD": { xrate: 7.8114, },
  "CAD": { xrate: 1.3188, },
};

export function getCurrency(country) {
  if(country && country.toUpperCase() in country_currency) return country_currency[country.toUpperCase()];
  return null;
}

export function getCurrencyName(currencyCode) {
  let i = findInObject(currency_names, "cc", currencyCode);
  if(i>=0) return currency_names[i].name;
  return currencyCode;
}

export function findInObject(array, key, value) {
  for(var i=0; i<array.length; i++) {
    // console.log(`Looking for ${value} in ${key} for ${JSON.stringify(array[i])}`);
    if((key in array[i] && array[i][key]===value)
      || (array[i].metadata && key in array[i].metadata && array[i].metadata[key]===value)) {
      // console.log(`Found value ${value} in ${key} at index ${i}`);
      return i;
    }
  }
  // console.log(`Could not find ${value} in ${key}`);
  return -1;
}

export function pluralize(n, singular, plural) {
  if(n==1) return `${n} ${singular}`;
  return `${n} ${plural}`;
}

export function CenteredCircularProgress(props) {
  return (
    <div style={{textAlign: "center", marginTop: 40, marginBottom: 40}}>
      <CircularProgress />
    </div>
  );
}
