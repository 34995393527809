import React from 'react';
import moment from 'moment';

function Footer(props) {
  return (
    <footer>
      <div>
      &copy; InviteOnly {moment().format('Y')} 
      &nbsp;
      <a href="mailto:hello@inviteonly.gg">hello@inviteonly.gg</a>
      </div>

      <div style={{marginTop: "16px"}}>
        <a href="/chat-group-paid-paywall-subscription.html">Paid Telegram chat groups</a>
        <br/>
        <a href="/zoom-google-meet-webex-paid-paywall-subscription.html">Paywall to Zoom, Google Meet, WebEx live video calls</a>
      </div>
    </footer>
  );
}

export default Footer;
