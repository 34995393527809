import React from 'react';
import { connect } from "react-redux";
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    marginTop: 80,
    textAlign: "center",
  }
});

function Interstitial(props) {
  if(props.url) window.location.href=props.url;
  const classes = useStyles();

  return (
    <div className="feed">
      <div className={classes.root}>
        <h1>Redirecting to {props.destination}...</h1>
        <CircularProgress />
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    url: state.redirectExpressUrl,
  };
}

export default connect(mapStateToProps)(Interstitial);

