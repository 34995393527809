import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { fetchGroup, activateGroup, updateSession } from '../store/actions';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import FormGroup from '@material-ui/core/FormGroup';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import TelegramIcon from '@material-ui/icons/Telegram';

import { ButtonLink, CenteredCircularProgress, whatsappLink, telegramLink } from '../utils/utils';

const useStyles = makeStyles({
  hero: {
    padding: "16px 0px",
  },
  form: {
    margin: "16px 0px",
  },
  submitBtn: {
    marginLeft: 24,
    position: "relative",
    top: 24,
  },
  shareBtns: {
    marginTop: 32,
    textAlign: "center",
  }
});

function InnerActivate(props) {
  const classes = useStyles();
  const { group } = props;
  const urlParams = new URLSearchParams(window.location.search);
  let chatId = urlParams.get('chat_id');
  if(chatId) props.updateChatId(chatId);
  else chatId = props.telegramChatId;

  useEffect( () => {
    if(!props.group) {
      props.loadGroup();
    }
  }, []);

  const activate = e => {
    e.preventDefault();
  };

  if(props.loggingIn || !props.profile || !group) return (
    <CenteredCircularProgress />
  );


  let activation = (
    <div>
      <Typography variant="body1" gutterBottom>
        Group <b>{group.name}</b> has already been activated and linked to a Telegram Group.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Share the group link below to get members: <a href={`/groups/${group.group_id}`}>InviteOnly.gg/groups/{group.group_id}</a>
      </Typography>

      <div className={classes.shareBtns}>
        <IconButton aria-label="share on WhatsApp"
            href={whatsappLink(group.name, 'https://inviteonly.gg/groups/' + group.group_id)}
        >
            <WhatsAppIcon />
        </IconButton>
        <IconButton aria-label="share on Telegram"
            href={telegramLink(group.name, 'https://inviteonly.gg/groups/' + group.group_id)}
        >
            <TelegramIcon />
        </IconButton>
      </div>
    </div>
  );
  if(!group.chat_id) {
    if(chatId) {
      console.log(`ActivateGroup: found chatId ${chatId}`);
      activation = (
        <div style={{ textAlign: "center" }}>
          <Typography variant="body1" gutterBottom>
            Click below to confirm activation of this group
          </Typography>
          <Button color="primary" variant="contained"
            onClick={ e => props.activate(group.group_id, chatId) }
            disabled={ props.isActivating }
          >
            {props.isActivating ? "Activating..." : "Activate"}
          </Button>
        </div>
      )
    } else {
      activation = (
        <div>
            <p>Follow these steps to manage your group subscriptions</p>
            <ol>
              <li>Create a new Telegram group or channel adding our bot <b><code>InviteOnlyBot</code></b></li>
              <li>Promote our bot <b><code>InviteOnlyBot</code></b> to admin to allow us to manage members: click on group header, long-press on <code>InviteOnlyBot</code>, select "Promote to Admin"</li>
              <li>Use the bot command <code>/new</code> to receive an activation link</li>
              <li>Click on the link and select the group to be activated</li>
            </ol>
        </div>
      );
    }
  }

  return (
    <div className="feed">
      <div className={classes.hero}>
        <h1>Activate group {group.name}</h1>
      </div>

      {activation}

    </div>

  );
}

const mapStateToProps = (state, ownProps) => {
  const { groupId } = ownProps.match.params;

  let group = null;
  if(state.groups && groupId in state.groups) group = state.groups[groupId];

  return {
    loggingIn: state.loggingIn,
    loggedIn: state.loggedIn,
    profile: state.profile,
    group: group,
    telegramChatId: state.session ? state.session.telegramChatId : null,
    isActivating: state.isActivatingGroup,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    loadGroup: () => {
      console.log(`Loading group ${ownProps.match.params.groupId}`);
      dispatch(fetchGroup(ownProps.match.params.groupId));
    },
    updateChatId: chatId => {
      dispatch(updateSession({telegramChatId: chatId}));
    },
    activate: (groupId, chatId) => dispatch(activateGroup(groupId, chatId)),
  }
}

const ActivateGroup = connect(mapStateToProps, mapDispatchToProps)(InnerActivate);
export default ActivateGroup;
