import React, { useState } from 'react';
import { connect } from "react-redux";
import { updateSession, apiRequest } from '../store/actions';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormGroup from '@material-ui/core/FormGroup';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { ButtonLink, CenteredCircularProgress } from '../utils/utils';
import LoggedHome from './LoggedHome';

const useStyles = makeStyles({
  hero: {
    padding: "20px 0px",
    marginBottom: 24
  },
  usp: {
    marginTop: 32,
  },
  form: {
    margin: "24px 0px 40px",
  },
  textfield: {
    marginRight: 24,
    minWidth: 300,
  },
  submitBtn: {
    position: "relative",
    top: 24,
  },
});

function InnerHomePage(props) {
  const classes = useStyles();
  const [validateUsername, setValidateUsername] = useState({ error: false, message: ""});


  const handleSubmit = e => {
    e.preventDefault();

  };

  const validate = () => {
    const slug = props.username ? props.username.trim() : null;

    if(!slug || slug.length==0) {
      setValidateUsername({ error: true, message: "Enter a URL" });
      return false;
    }
    if(slug.length<6 || slug.length>30) {
      setValidateUsername({ error: true, message: "Your URL should be 6 to 30 characters long"});
      return false;
    }
    const pattern = /^[a-zA-Z0-9_]+$/;
    if(!pattern.test(slug)) {
      setValidateUsername({ error: true, message: "Only letters, numbers and underscore _ allowed" });
      return false;
    }
    apiRequest(`/shortcodes/${slug}`)
      .then( json => {
        console.log(`Found slug ${slug}, received json ${JSON.stringify(json)}`);
        if(json.data.company) {
          if(json.data.company.company_urlsafe != this.props.company.company_urlsafe) {
            setValidateUsername({ error: true, message: `${slug} is already taken` });
          } else {
            console.log(`Slug ${slug} is owned by this company ${this.props.company.company_urlsafe}`);
          }
        }
      })
      .catch(function(error) {
        console.log("Could not fetch company due to exception");
        console.log(error);
        if(error.response && error.response.status===404) {
          console.log(`Slug ${slug} is available`);
        }
      });

    setValidateUsername({ error: false, message: ""});
    return true;
  }

  const checkUsername = e => {
    const valid = validate();
    if(e && !valid) e.preventDefault();
  }

  const handleChange = e => {
    props.updateUsername(e.target.value);
    checkUsername();
  }

  if(props.loggingIn) return (
    <CenteredCircularProgress />
  );
  else if(props.loggedIn) return <LoggedHome />;

  const next = `/dashboard?username=${encodeURIComponent(props.username)}`;
  return (
    <div className="feed">
      <div className={classes.hero}>
        <h1>
          Private chat groups and video calls
        </h1>
        <Typography variant="body1" color="textSecondary" gutterBottom>
          Build your community. Make money from subscriptions.
        </Typography>
      </div>

      <form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <div className={classes.form}>
          <TextField
            label="Username"
            name="username"
            className={classes.textfield} 
            helperText={validateUsername.error ? validateUsername.message : "Pick your personal URL"}
            value={props.username}
            autoFocus={true}
            onChange={handleChange}
            onBlur={validate}
            margin="normal"
            error={validateUsername.error}
            InputProps={{
              startAdornment: <InputAdornment position="start">InviteOnly.gg/</InputAdornment>
            }}
          />
          <Button
              className={classes.submitBtn} 
              component={ButtonLink} 
              to={`/login?next=${encodeURIComponent(next)}`}
              variant="contained" color="primary"
              onClick={checkUsername}
          >
            Get Started
          </Button>
        </div>
      </form>

      <div className={classes.usp}>
        <Typography variant="h6" color="textPrimary" gutterBottom>
          Set your price
        </Typography>
        <Typography variant="body1" color="textSecondary" gutterBottom>
          Make your private group free, or charge any one-off, monthly or yearly subscription fee.
        </Typography>
      </div>

      <div className={classes.usp}>
        <Typography variant="h6" color="textPrimary" gutterBottom>
          Pick your tool
        </Typography>
        <Typography variant="body1" color="textSecondary" gutterBottom>
          Offer paid access to Telegram groups and channels, Discord servers, Zoom video calls, Google Meet 
          online meetings, WebEx. <br/>
          InviteOnly makes it easy to offer a simple paywall to any online communication tool.
        </Typography>
      </div>

      <div className={classes.usp}>
        <Typography variant="h6" color="textPrimary" gutterBottom>
          Why Telegram?
        </Typography>
        <Typography variant="body1" color="textSecondary" gutterBottom>
          Telegram keeps your and your subscribers' phone numbers confidential and 
          it makes it possible for us to manage your subscribers automatically.
        </Typography>
        <Typography variant="body1" color="textSecondary" gutterBottom>
          You can manage your paid groups on our simple web interface, no need to interact with chat bots.
        </Typography>
      </div>

      <div className={classes.usp}>
        <Typography variant="h6" color="textPrimary" gutterBottom>
          Low fees
        </Typography>
        <Typography variant="body1" color="textSecondary" gutterBottom>
          We will charge a 9% fee on top of payment processor fees, no minimums or recurring monthly fees.<br/>
          <br/>
          If a referrer is to be credited for a sale, they will retain a 15% commission.<br/>
          You will keep the remainder.
        </Typography>
      </div>

    </div>

  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    loggingIn: state.loggingIn,
    loggedIn: state.loggedIn,
    profile: state.profile,
    username: state.session.username || "",
    groups: state.groups,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateUsername: username => {
      dispatch(updateSession({username: username}));
    },

  }
}

const HomePage = connect(mapStateToProps, mapDispatchToProps)(InnerHomePage);
export default HomePage;
