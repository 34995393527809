import React from 'react';
import { connect } from "react-redux";
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = {};

class InnerSignin extends React.PureComponent {
  
  constructor(props) {
    super(props);
    this.state = {
      firebaseLogin: null
    };
    let query = null;
    let next = null;
    if(this.props.history && this.props.history.location) query = this.props.history.location.search;
    if(query && query.length>1) {
      console.log(`Looking at query string ${query}`);
      let kvs = query.substring(1).split('&');
      for(let i=0; i<kvs.length; i++) {
        let kv = kvs[i].split('=');
        console.log(`Looking at key value ${kv}`);
        if(kv[0]=="next") {
          next = decodeURIComponent(kv[1]);
          break;
        }
      }
    }

    var self = this;
    // code splitting for largest library imported into bundle
    import('firebase/app').then(firebase => {
      import('firebase/auth').then(firebase_auth => {
      import('react-firebaseui/StyledFirebaseAuth').then(StyledFirebaseAuth => {
      import('firebaseui').then(firebaseui => {

        var country = null;
        if(this.props.geo && this.props.geo.country) {
          country = this.props.geo.country;
        }
        console.log(`Country in Signin page is ${JSON.stringify(country)}`);
        // UI config for auth
        const uiConfig = {
            signInFlow: "redirect",
            signInSuccessUrl: this.props.next || next || '/',
            signInOptions: [
              // Leave the lines as is for the providers you want to offer your users.
              /*
              {
                provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
                authMethod: 'https://accounts.google.com',
                clientId: '1045477241356-a79n4kfsv76t65ee0hrinfmt5i49f592.apps.googleusercontent.com',
              },*/
              //firebase.auth.FacebookAuthProvider.PROVIDER_ID,
              //firebase.auth.TwitterAuthProvider.PROVIDER_ID,
              //firebase.auth.GithubAuthProvider.PROVIDER_ID,
              //firebase.auth.EmailAuthProvider.PROVIDER_ID,
              {
                provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
                defaultCountry: country || "SG",
              }, 
              //firebaseui.auth.AnonymousAuthProvider.PROVIDER_ID
            ],
            // credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO,
            // tosUrl and privacyPolicyUrl accept either url string or a callback
            // function.
            // Terms of service url/callback.
            tosUrl: 'https://www.ezail.com/terms',
            // Privacy policy url/callback.
            privacyPolicyUrl: 'https://www.ezail.com/privacy'
          };

        self.setState({
          firebaseLogin: (
              <StyledFirebaseAuth.default uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
          )
        });
      });
      });
      });
    });
  }

  render() {
    return (
      <div className="feed">
        <div style={{margin: "20px 0px", textAlign: "left"}}>
          <Typography variant="body1" gutterBottom>
            InviteOnly allows you to manage paid subscriptions to your group chats.
            Invite your followers and make money.
          </Typography>

          <Typography variant="body1" gutterBottom>
            Login with your mobile phone number below.
          </Typography>
        </div>

        {this.state.firebaseLogin}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    geo: state.geo,
  }
}

const Signin = connect(mapStateToProps)(InnerSignin);

export default withStyles(styles)(Signin);
